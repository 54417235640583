export default {
    navigation:[
        // {
        //   name:"首页",
        //   url:"/",
        //   key:'home'
        // },
        {
          name:"ABOUT US",
          url:"/about",
          key:'about'
        },
        {
          name:"PRODUCT LINES",
          url:"/brand",
          key:'brand'
        },
        {
          name:"CORE ADVANTAGES",
          url:"/advant",
          key:"advant"
        },
        {
          name:"INTELLIGENT FACTORIES",
          url:"/plant",
          key:"plant",
          
        },
        {
          name:"COOPERATION",
          url:"/coop",
          key:'coop'
        },
        
    ],
    footer:{
        index:{
            gzh:"Scan and follow the public account",
            wx:"Contact customer service on wechat",
            beian:"Copyright © 2018 Rizhao OSBORNE Decoration Material CO.,LTD. property in copyright        ",
            beianhao:"Internet Content Provider：浙ICP备18027908号-3",
            friendLink:{
                title:"blogroll",
                links:[{
                    name:"Millennium Boat Group official website",
                    links:"three.com"
                }]
            }
        },
        links: [
          {
              title: 'Media Center',
              links: [{
                  name: 'Factory Updates',
                  url: '/news/0'
              }, {
                  name: 'Product Updates',
                  url: '/news/1'
              }, {
                  name: 'News',
                  url: '/news/2'
              }, {
                  name: 'Video Center',
                  url: '/video/3'
              }]
          }, {
              title: 'Join Us',
              links: [{
                  name: 'Job Application',
                  url: '/add'
              }, {
                  name: 'Corperate Culture',
                  url: '/add'
              }]
          }, {
              title: 'Contact Us',
              links: [{
                  name: 'Telephone',
                  url: '/content'
              }, {
                  name: 'Company Address',
                  url: '/content'
              }, {
                  name: 'Wechat Subscription',
                  url: '/content'
              }]
          }
        ],
        bottomData: [{
            title: "Apply to be our partner",
            desc: "We are committed to providing world-class products and services that satisfy our customers.",
            btnName: "Apply to join"
        }, {
            title: "Apply to be our partner",
            desc: "With thinking development, business management, sales skills, lean management and many other courses waiting for you to learn!",
            btnName: "submit a resume"
        }]
    },
    coop:{
        search:{
            input:"Enter the license serial number or company name",
            button:"Query authorization",
            table:{
                id:"Serial number",
                conpany:"Company name",
                date:"Time",
                begave:"Operate",
                selct:"Look over"
            }
        }
    },
    news:{
        more:"Click for more"
    },
    newsDetail:{
        next:"Prev",
        prev:"Next",
        back:"Back"
    },
    content:{
        title1:"Contact way",
        title1Desc:"We always pay attention to your innermost needs, and constantly break through to deliver works that surprise and surprise you. If you need, please contact us!",
        title2:"Osborne · service hotline",
        qr:"pay attention to us!",
        center:"Headquarters:",
        adds:"Address:",
        email:"E-mail:",
        coordinate:"coordinate:",
        tel:"Tel:",
        canyAdds:"Company address"
    },
    add:{
        title:"Apply to join",
        info:"We are committed to providing world-class products and services that satisfy our customers. Apply to be our partner! <br /> If you need, please contact us, we will reply to your application as soon as possible, please feel free to fill in, your information will be confidential processing!",
        product:"Product intention*",
        name:"Your name",
        iptName :"Please enter text",
        tel:"Phone*",
        iptTel:"Please enter the number",
        adds:"Contact address*",
        iptAdds:"Please enter text",
        idear:"Please input your thoughts",
        btnText:"Submit",
        // 工作
        jobTitle:"New Jobs",
        jobBtnText:"Click through for more job postings",
        jobemail:"Send your resume to email"
    }
}