<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view :key="index" />
</template>
<script>
import {getBrowser} from './js/getBrowser.js'
import { mapMutations } from 'vuex'

export default {
  data:function(){
    return {
      index:0
    }
  },
  methods:{
    ...mapMutations(['setBaseUrl'])
  },
  mounted(){
    if(getBrowser() == 'trident'){
      console.log('ie浏览器')
    }
    console.log(this.$store.state.lang)
  },
  watch:{
    $route: {
      handler: function() {
        // console.log('comin channel-edit',route)
        this.index++
      },
      immediate: true
    },
    "$store.state.lang":{
      handler: function(val) {
        console.log(val)
        if(val == 'cn'){
          this.setBaseUrl('http://121.89.216.1:18061/ausbourne-admin/')
        }else if(val == 'en'){
          this.setBaseUrl('http://121.89.216.1:18060/ausbourneen-admin/')
        }
        this.index++

      },
      immediate: true
    }
  }
}
</script>
<style lang="less">
  @import url('./css/public.css');
  @import url('font-awesome');
  @import url('animate.css');
  
  .showSwiper{
    animation-name: showSwiper;
  }

  @keyframes showSwiper {
    0%{
      margin-left: 0px;
    }
    50%{
      margin-left: -100px;
    }
    100%{
      margin-left: 0px; 
    }
  }
</style>
