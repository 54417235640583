import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/m',
    name: 'home',
    component: HomeView,
    meta:{
      active_key:"home",
      title:"澳思柏恩"
    }
  },
  // 关于我们
  {
    path: '/about/:lc?',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta:{
      active_key:"about",
      title:"关于我们__澳思柏恩"
    }
  },
  // 品牌中心
  {
    path: '/brand/:id?',
    name: 'brand',
    component: () => import(/* webpackChunkName: "about" */ '../views/BrandView.vue'),
    meta:{
      active_key:"brand",
      title:"品牌中心__澳思柏恩"
    }
  },
  // 品牌详情页
  {
    path: '/brand/:id/:brand_id',
    name: 'brandDetail',
    meta:{
      active_key:"brand",
      title:"品牌中心__澳思柏恩"
    },
    query:{
      id:Number,
      brand_id:Number
    },
    afterEach:() => {
      document.body.scrollTop = 0
      return true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/BrandDetailView.vue')
  },
  // 核心优势
  {
    path: '/advant',
    name: 'advant',
    // query:{
    //   id:Number
    // },
    meta:{
      active_key:"advant",
      title:"核心优势__澳思柏恩"
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/advantageView.vue')
  },
   // 智能制造
   {
    path: '/advant/intel/:id?',
    name: 'intel',
    query:{
      id:Number
    },
    meta:{
      active_key:"advant",
      title:"智能制造__澳思柏恩"
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/IntelligentView.vue')
  },
  // 资源优势
  {
    path: '/advant/reso/:id?',
    name: 'reso',
    // query:{
    //   id:Number
    // },
    meta:{
      active_key:"advant",
      title:"资源优势__澳思柏恩"
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/ResourceView.vue')
  },
  // 智能工厂
  {
   path: '/plant/:id?',
   name: 'plant',
   query:{
     id:Number
   },
   meta:{
    active_key:"plant",
    title:"智能工厂__澳思柏恩"
  },
   component: () => import(/* webpackChunkName: "about" */ '../views/PlantView.vue')
 },
  //  招商合作
  {
    path: '/coop/:id?',
    name: 'coop',
    query:{
      id:Number
    },
    meta:{
      active_key:"coop",
      title:"招商合作__澳思柏恩"
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/CooperationView.vue')
  },
  //  新闻中心
  {
    path: '/news/:id?',
    name: 'news',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta:{
      active_key:"news",
      title:"新闻中心__澳思柏恩"
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/NewsView.vue')
  },
  //  视频中心
  {
    path: '/video/:id',
    name: 'video',
    meta:{
      active_key:"video",
      title:"视频中心__澳思柏恩"
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/VideoEle.vue')
  },
   //  新闻详情
   {
    path: '/news/detail/:id',
    name: 'newsdetail',
    meta:{
      active_key:"newsdetail",
      title:"新闻详情__澳思柏恩"
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/newsDetail.vue')
  },
  //  联系我们
  {
    path: '/content',
    name: 'content',
    meta:{
      active_key:"content",
      title:"联系我们__澳思柏恩"
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ContentView.vue')
  },
  //  加入我们
  {
    path: '/add/:id?',
    name: 'add',
    meta:{
      active_key:"add",
      title:"加入我们__澳思柏恩"
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AddView.vue')
  },
  {
    path: '/',
    name: 'home',
    meta:{
      active_key:"home",
      title:"澳思柏恩"
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/TestView.vue')
  },
  {
    path: '/nav',
    name: 'nav',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
router.beforeEach(() => {
  document.body.scrollTop = 0
  return true
})

export default router
