<template>
	<div class="about">
		<div v-if="page.isM" class="content">
			<div class="pic"><img :src="data.imgUrl" alt=""></div>

			<h1 :class=" isActive ? 'title-en animate__fadeInUp' : 'title-en wow animate__fadeInUp'"
				style="animation-delay:.1s ;">{{data.name}}
			</h1>
			<h1 :class="isActive ? 'title-cn animate__fadeInUp' : 'title-cn wow animate__fadeInUp '"
				style="animation-delay:.2s ;" v-html="data.title"></h1>
			<p :class="isActive ? 'desc animate__fadeInUp' : 'desc wow animate__fadeInUp'" style="animation-delay:.3s ;"
				v-html="data.desc">
			</p>
			<more :url="data.url" :class="isActive ? 'animate__fadeInUp' : 'wow animate__fadeInUp'"
				style="animation-delay:.4s;"></more>
		</div>
		<div v-if="!page.isM" class="content">
			<div class="pic"><img :src="data.imgUrl" alt=""></div>

			<h1 :class=" isActive ? 'title-en animate__fadeInUp' : 'title-en'" style="animation-delay:1.1s ;">
				{{data.name}}
			</h1>
			<h1 :class="isActive ? 'title-cn animate__fadeInUp' : 'title-cn'" style="animation-delay:1.2s ;"
				v-html="data.title"></h1>
			<p :class="isActive ? 'desc animate__fadeInUp' : 'desc'" style="animation-delay:1.3s ;" v-html="data.desc">
			</p>
			<more :url="data.url" :class="isActive ? 'animate__fadeInUp' : ''" style="animation-delay:1.4s;"></more>
		</div>
		<div v-if="page.isM" class="imgs">
			<div class="commoue">
				<Swiper :modules="modules" :autoplay="{
                    delay: 2500,
                    disableOnInteraction: false,
                    }" :pagination="{clickable: true}" class="mimgs">
					<SwiperSlide class="imgs-slide" v-for="(item,index) in data.imgs" :key="index">
						<div :class="'pic img'+(index+1)">
							<img :src="item.imgUrl" alt="">
							<span>{{item.title}}</span>
						</div>
					</SwiperSlide>
				</Swiper>
			</div>
		</div>
		<div v-if="!page.isM" class="imgs">

			<!-- <div :class="isActive ? 'commoue commoue-rever light' : 'commoue commoue-rever'"> -->
			<div :class="isActive ? 'commoue commoue-rever' : 'commoue commoue-rever'">
				<!-- <div v-for="(item,index) in data.imgs" :key="index" :class="i % 2 ==0 ? 'animate__fadeInDown pic img'+(index+1) : 'animate__fadeInUp pic img'+(index+1) ">
                    <img :src="item.imgUrl" alt="">
                    <span>{{item.title}}</span>
                </div> -->
				<div :class="isActive ? 'animate__fadeInDown pic img1' : 'pic img1'" style="animation-delay: 1.1s;">
					<img :src="data.imgs[0].imgUrl" alt="">
					<span><router-link :to="data.imgs[0].url">{{data.imgs[0].title}}</router-link></span>
				</div>
				<div :class="isActive ? 'animate__fadeInUp pic img2' : 'pic img2'" style="animation-delay: 1.1s;">
					<img :src="data.imgs[1].imgUrl" alt="">
					<span><router-link :to="data.imgs[1].url">{{data.imgs[1].title}}</router-link></span>

				</div>
				<div :class="isActive ? 'animate__fadeInDown pic img3' : 'pic img3'" style="animation-delay: 1.1s;">
					<img :src="data.imgs[2].imgUrl" alt="">
					<!-- <span>{{data.imgs[2].title}}</span> -->
					<span><router-link :to="data.imgs[2].url">{{data.imgs[2].title}}</router-link></span>

				</div>

				<div :class="isActive ? 'animate__fadeInUp pic img4' : 'pic img4'" style="animation-delay: 1.1s;">
					<img :src="data.imgs[3].imgUrl" alt="">
					<!-- <span>{{data.imgs[3].title}}</span> -->
					<span><router-link :to="data.imgs[3].url">{{data.imgs[3].title}}</router-link></span>

				</div>

			</div>
			<!-- <div :class=" isActive ? 'commoue animated' : 'commoue' "> -->
			<!-- <div :class=" isActive ? 'commoue lightRever' : 'commoue' ">
                <div v-for="(item,index) in data.imgs" :key="index" :class="i % 2 ==0 ? 'animate__fadeInDown pic img'+(index+1) : 'animate__fadeInUp pic img'+(index+1) ">
                    <img :src="item.imgUrl" alt="">
                    <span>{{item.title}}</span>
                </div>
            </div> -->
		</div>
		<!-- <div v-if="!page.isM" class="imgs">
            <div class="commoue lightRever ">
                <div v-for="(item,index) in data.imgs" :key="index" :class="'pic img'+(index+1)">
                    <img :src="item.imgUrl" alt="">
                    <span>{{item.title}}</span>
                </div>
            </div>
            <div class="commoue commoue-rever light">
                <div v-for="(item,index) in data.imgs" :key="index" :class="'pic img'+(index+1)">
                    <img :src="item.imgUrl" alt="">
                    <span>{{item.title}}</span>
                </div>
            </div>
        </div> -->
	</div>
</template>
<script>
	import more from '../../components/more/MoreEle.vue'

	import {
		Swiper,
		SwiperSlide
	} from 'swiper/vue';
	import {
		Pagination,
		Autoplay
	} from 'swiper';
	import {
		mapState
	} from 'vuex';
	import ajax from '@/ajax/index.js';

	export default {
		props: ["isActive"],
		components: {
			more,
			Swiper,
			SwiperSlide
		},
		data() {
			return {
				page: {
					isM: true
				},
				data: {
					imgUrl: "",
					url: "",
					name: "ABOUS",
					title: "专注于居家环保建材的<br />研发、制造和销售",
					desc: `澳思柏恩系千年舟集团旗下品牌，创建于2016年，专注于居家环保建材的研发、制造、销售和服务。目前，已成立三大智能工厂，形成以日照、郯城、邳州三大智能工厂为中心，辐射全国的产品供应体系。澳思柏恩品牌成立以来，屡获殊荣，先后获得“十大刨花板品牌”、“板材优秀制造企业”、“日照市林业产业龙头企业”等多项殊荣。`,
					imgs: [{
							title: "全球化采购",
							imgUrl: require("../../assets/index/e2.png"),
							url:"/advant/reso",
						},
						{
							title: "全球化采购",
							imgUrl: require("../../assets/index/e3.png"),
							url:"/advant/reso",
						},
						{
							title: "全球化采购",
							imgUrl: require("../../assets/index/e4.png"),
							url:"/advant/reso"
						},
						{
							title: "全球化采购",
							imgUrl: require("../../assets/index/e5.png"),
							url:"/advant/reso"
						},
					]
				}
			}
		},
		mounted: function() {
			console.log(ajax)
			let _this = this
			ajax(_this.baseUrl + "web/showaboutService").then(res => {
				let result = res;
				let showabout = [];
				console.log(result[0].aboutEname)
				console.log(showabout)

				_this.data.imgUrl = _this.baseUrl + result[0].aboutImg;
				_this.data.url = result[0].aboutUrl ? 'result[0].aboutUrl' : '/about';
				_this.data.name = result[0].aboutEname;
				_this.data.title = result[0].aboutTitle;
				_this.data.desc = result[0].aboutParticulars;
				
			});
			ajax(_this.baseUrl + "web/showaboutlistService").then(res => {
				let result = res;
				let showaboutlist = [];
				/* {
					title: "全球化采购",
					imgUrl: require("../../assets/index/e5.png")
				} */
				
				result.forEach((item,index) => {
					console.log(item.name)
					showaboutlist.push({
						title: item.aboutName,
						imgUrl: _this.baseUrl + item.aboutImg,
						url:_this.data.imgs[index].url
					});
				});
			
			_this.data.imgs = showaboutlist;
				
				
			});
			// 页面初次进入加载
			if (document.body.clientWidth > 769) {
				this.page.isM = false
			}
			// 宽度改变切换
			window.onresize = function() {
				if (document.body.clientWidth > 769) {
					_this.page.isM = false
				} else {
					_this.page.isM = true
				}
			}
		},
		computed: {
			...mapState(["baseUrl"])
		},
		setup() {
			return {
				modules: [Pagination, Autoplay]
			}
		}
	}
</script>
<style>
	.mimgs .swiper-pagination {
		display: flex;
		margin-left: 15px;
		width: 70%;
		height: 2px;
		/* background:black; */
		margin-left: 30px;
		bottom: 9% !important;
		display: flex;
		justify-content: space-between !important;
	}

	.mimgs .swiper-pagination-bullet {
		background: rgb(255, 255, 255, .5) !important;
		display: block;
		height: 100%;
		flex: 1;
		display: block;
	}

	.mimgs .swiper-pagination-bullet-active {
		background: rgb(255, 255, 255, .9) !important;
	}
</style>
<style lang="less" scoped>
	.about {
		width: 100%;
		height: 100vh;
		display: flex;
		background: #fff;

	}

	.content {
		// flex: 1;
		width: 50%;
		box-sizing: border-box;
		padding-left: 8%;
		padding-top: 14.2%;
		background-color: #dedede;
		// background-image: url(../../assets/index/e1.png);
		background-position: center center;
		background-size: 70%;
		background-repeat: no-repeat;
		position: relative;

		.pic {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;

			img {
				width: 60%;
				height: auto;
				animation-name: backRotate;
				animation-duration: 20s;
				animation-iteration-count: infinite;
			}
		}

		.title-en {
			font-size: 3.6rem;
			font-weight: 400;
			line-height: 1;
			// letter-spacing: 0px;
			margin-bottom: 56px;
			color: #00543e;
		}

		.title-cn {
			font-size: 5rem;
			font-weight: 400;
			line-height: 1.2;
			margin-bottom: 30px;
			color: #00543e;
			width: 71%;
		}

		.desc {
			line-height: 1.9;
			letter-spacing: 3.8px;
			margin-bottom: 137px;
			color: #333;
			width: 70%;

			// font-size: 1.6rem;

		}

		.more {
			color: #333;
		}
	}

	.imgs {
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		flex-wrap: wrap;
		align-content: space-between;
		overflow: hidden;


		.commoue {
			width: 50%;
			height: 100%;
			display: flex;
			flex-direction: column;
			// animation-name: lightRever;
			// -webkit-animation-duration: 20s;
			// animation-duration: 20s;
			// animation-fill-mode: both;
			flex-wrap: nowrap;
			transform: translateY(-100vh);

			&.animated * {
				-webkit-animation-duration: 1s;
				animation-duration: 1s;
				-webkit-animation-duration: var(--animate-duration);
				animation-duration: var(--animate-duration);
				-webkit-animation-fill-mode: both;
				animation-fill-mode: both;
			}

			.pic {
				flex-shrink: 0;
				width: calc(100% - 7px);
				margin-bottom: 7px;

				span {
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
					font-size: 2.4rem;
					color: #fff;
					&:hover{
						a{
							color: #b9985e;
						}
					}
					a{
						color: #fff;
						transition: .3s;
					}
				}
			}

			.img1 {
				height: 62.5%;
			}

			.img2 {
				height: 36%;
			}

			.img3 {
				height: 36%;
			}

			.img4 {
				height: 62.5%;
			}
		}

		.commoue-rever {
			flex-direction: column-reverse;
			// animation-name: light;
			transform: translateY(0vh);
			width: 100%;
			flex-wrap: wrap;
			// -webkit-animation-duration: 20s;
			// animation-duration: 20s;
			// animation-fill-mode: both;

			.pic {
				// width: (50% - 3px) ;
				width: calc(50% - 7px);

				img {
					transition: .7s;
				}

				&:hover {
					img {

						transform: translate(-50%, -50%) scale(1.2);
					}
				}
			}

			.img1 {
				height: 62.5%;
				order: 4;
			}

			.img2 {
				height: 35%;
				order: 3;
			}

			.img3 {
				height: 35%;
				order: 2;
			}

			.img4 {
				height: 62.5%;
				order: 1;
			}
		}

	}

	@media all and (max-width:1500px) {
		.content {
			padding-top: 6.2%;
		}
	}

	@media all and (max-width:1200px) {
		.content {
			.title-cn {
				font-size: 3.1rem;
			}

			.desc {
				margin-bottom: 75px;
			}
		}

		.imgs {
			.commoue {
				.pic {
					text-align: center;
				}
			}
		}
	}

	@media all and (max-width:800px) {
		.about {}

		.content {
			.title-en {}

			.title-cn {}

			.desc {
				margin-bottom: 45px;
			}

			.more {}
		}

		.imgs {
			.commoue {
				.pic {
					span {}
				}

				.img1 {}

				.img2 {}

				.img3 {}

				.img4 {}
			}

			.commoue-rever {
				.img1 {}

				.img2 {}

				.img3 {}

				.img4 {}
			}

		}
	}

	@media all and (max-width:769px) {
		.about {
			height: auto;
			flex-wrap: wrap;

			.content {
				width: 100%;
				padding: 60px 15px;
				order: 1;
				.title-cn{
					width: 100%;
				}
				.desc {
					width: 100%;
					// font-size: 1.4rem;
				}
			}

			.imgs {
				order: 2;
				display: flex;
				flex: none;
				width: 100%;
				height: 40vh;

				.commoue {
					// width: calc(50% - 3px);
					// width: calc(50% - 3px);
					width: 100%;
					flex-direction: row;
					animation-name: none;
					transform: translateY(0);

					.pic {
						width: 100%;
						height: 100%;
					}

					.mimgs {
						width: 100%;
					}
				}

				.commoue-rever {
					display: none;
				}
			}

		}
	}

	@media all and (max-width:1px) {
		.about {}

		.content {
			.title-en {}

			.title-cn {}

			.desc {}

			.more {}
		}

		.imgs {
			.commoue {
				.pic {
					span {}
				}

				.img1 {}

				.img2 {}

				.img3 {}

				.img4 {}
			}

			.commoue-rever {
				.img1 {}

				.img2 {}

				.img3 {}

				.img4 {}
			}

		}
	}

	.light {
		animation-name: light;
	}

	.lightRever {
		animation-name: lightRever;
	}

	@keyframes light {
		0% {
			transform: translateY(100vh);
		}

		100% {
			transform: translateY(0);
		}
	}

	@keyframes lightRever {
		0% {
			transform: translateY(-100vh);
		}

		100% {
			transform: translateY(0vh);
		}
	}

	@keyframes backRotate {
		0% {
			transform: translate(-50%, -50%) rotate(0deg);
		}

		100% {
			transform: translate(-50%, -50%) rotate(360deg);
		}
	}
</style>
