<template>
    <transition  :duration="{ enter: 300, leave: 300 }">
        <!-- v-if="videoOptions.isActive" -->
        <div style="color:#fff" v-show="isActive" class="video-conitioner active" v-on:click.stop="setVideo(false)">
            <div class="video">
                <video controls ref="video" id="video" :src="option?option.videoUrl:''"></video>
            </div>
        </div>   
    </transition> 
</template>
<script>
import {mapState} from 'vuex'
export default {
    props:{
        videoUrl:Object,
        option:{
            default:{videoUrl:''}
        }
    },
    data(){
        return {
            isActive : false
        }
    },
    computed:{
        ...mapState(['videoOptions'])
    },
    methods:{
        // ...mapMutations(['setVideo'])
        setVideo(){
            document.querySelector('#video').pause ()

            this.isActive = false
        }
    },
    watch:{
        immediate:true,
        ['option'](){
            console.log()
            this.isActive = true
            // document.querySelector('#video').play()
            console.log(document.querySelector('#video'))
            document.querySelector('#video').play()
            // this.$refs.video.play();
        }
    }
    
}
</script>
<style lang="less" scoped>
.video-conitioner.active{
    position: fixed;
    z-index: 99999;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, .5);
    
    .video{
        position:absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        height: 0;
        overflow: hidden;
        transition: .3s;
        -webkit-animation-duration: .3s;
        animation-duration: .3s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        animation-delay: .4s;
        animation-name: mover;
    }
    video{
        width: 70%;
        height: 70%;
        background: #000;

        position:absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
    }
}



@media (any-hover: hover) {
    .video-conitioner.active:hover{
        .video{
            height: 100%;
        }
    }
}
@keyframes mover{
    0%{
        height: 0;
    }
    100%{
        height: 100%;
    }
}
.v-enter-active,
.v-leave-active {
    transition: opacity 0.5s ease;
}
v-leave-from{
    opacity: 1;
}
.v-leave-to {
    opacity: 0;
    pointer-events: none;
}
</style>