<template>
    <nav id="nav">
        <transition name="fade" mode="out-in" enter-active-class="animate__fadeIn"
            :duration="{ enter: 300, leave: 300 }">
            <div class="mask pic">
                <img style="height: 20%; top: 10%; object-fit: fill;" src="@/assets/index/mask.png" alt="">
            </div>
        </transition>

        <!-- 
            _this.setDom.meidia.l = true
                    _this.setDom.meidia.m = false
                    _this.setDom.meidia.xs = false
         -->

        <div v-if="setDom.meidia.l" class="content">
            <div class="logo pic">
                <router-link to="/">
                    <img src="../../assets/loag1.png" alt="">
                </router-link>
            </div>
            <!-- :class="setDom.mittle ? '' : 'active'" -->
            <div class="nav-links">
                <ul :class="{ active: setDom.mittle }">
                    <!-- @mousemove="" -->
                    <li :class="{ activeColor: active == 'home' }">
                        <router-link to="/">{{lang == "cn" ? '首页' : 'Home'}}</router-link>
                    </li>
                    <!-- :class="active == item.key ? 'liActive' : ''"  -->
                    <li v-for="(item, index) in langUse" @mousemove="setNaveActiveHoverMove" @mouseout="setRemoveAvtive"
                        @click="setNavAvtive($event, index)"
                        :class="{ active: active == item.key, activeColor: active == item.key }" :key="index">
                        <router-link :to="item.url">{{ item.name }}</router-link>
                        <div ref="classify" v-if="domData[index]" style="color:#fff" class="classify">
                            <div class="links">
                                <div style="color:#fff" v-for="(classItem, cIndex) in domData[index]?.data"
                                    :key="cIndex" class="classify-item"
                                    @mouseenter.stop="setClassImg(classItem.imgurl, cIndex)">

                                    <div class="title">
                                        <router-link class="link" :to="classItem.url ? classItem.url : ''">{{ classItem.title ? classItem.title : ''}}</router-link>
                                        <ul class="two">
                                            <li v-for="(item, i) in classItem.child"
                                                @mouseenter.stop="setClassImg(item.imgurl, index)" :key="i">
                                                <router-link class="two-link" :to="item.url">{{ item.title
                                                }}</router-link>
                                                <i class="fa fa-play" aria-hidden="true"></i>
                                                <ul class="three">

                                                    <li v-for="(it, i) in item.child" @mouseenter.stop="setClassImg(it.imgurl, index)" :key="i">
                                                        <router-link class="link" :to="it.url">{{ it.name }}</router-link>
                                                        <i class="fa fa-play" aria-hidden="true"></i>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div v-if="domData[index]" class="pic">
                                <transition name="fade" mode="out-in" enter-active-class="animate__fadeIn"
                                    :duration="{ enter: 300, leave: 300 }">
                                    <img class="img" :src="domData[index]?.activeImg" :key="domData[index]?.key" />
                                </transition>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="tel">
                <div class="icon pic"></div>
                <p>400-6801881</p>
            </div>
            <div class="search-conitioner" @mouseleave="blur">
                <input type="text" v-model="getSearch" @keyup="setSearchValue" >
                <i class="fa fa-search" aria-hidden="true"></i>
                <div ref="searchValue" class="search-value">
                    <ul class="">
                        <li v-for="(item, index) in searchValue" :key="index">
                            <router-link :to="item.url">{{ item.name }}</router-link>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="langs" @mouseleave="setLangTab">
                <div @mouseenter="setLangTab" class="title">{{ this.lang }}<i class="fa fa-angle-down"
                        aria-hidden="true"></i>
                </div>
                <div class="select">
                    <ul :class="setDom.langTab ? 'active' : ''">
                        <li @click="setStateLang" v-for="(item, index) in langs" :data-lang="item" :key="index">{{ item
                        }}
                        </li>
                    </ul>
                </div>

            </div>
        </div>
        <div v-if="setDom.meidia.m" class="content">
            <div class="logo pic">
                <router-link to="/">
                    <img src="../../assets/loag1.png" alt="">
                </router-link>
            </div>
            <!-- :class="setDom.mittle ? '' : 'active'" -->
            <div :class="{ 'nav-links': true, active: setDom.mittle }">
                <!-- <ul :class="{ active: setDom.mittle }"></ul> -->
                <ul>
                    <!-- @mousemove="" -->
                    <li :class="{ activeColor: active == 'home' }">
                        <router-link to="/">{{lang == "cn" ? '首页' : 'Home'}}</router-link>
                    </li>
                    <!-- :class="active == item.key ? 'liActive' : ''"  -->
                    <li v-for="(item, index) in langUse" @mousemove="setNaveActiveHoverMove" @mouseout="setRemoveAvtive"
                        @click="setNavAvtive($event, index)"
                        :class="{ active: active == item.key, activeColor: active == item.key }" :key="index">
                        <router-link :to="item.url">{{ item.name }}</router-link>
                        <div ref="classify" v-if="domData[index]" style="color:#fff" class="classify">
                            <div class="links">
                                <div style="color:#fff" v-for="(classItem, cIndex) in domData[index]?.data"
                                    :key="cIndex" class="classify-item"
                                    @mouseenter.stop="setClassImg(classItem.imgurl, cIndex)">
                                    <div class="title">
                                        <router-link class="link" :to="classItem.url ? classItem.url : ''">{{ classItem.title ? classItem.title : '' }}</router-link>
                                        <ul class="two">
                                            <li v-for="(item, i) in classItem.child"
                                                @mouseenter.stop="setClassImg(item.imgurl, index)" :key="i">

                                                <router-link class="two-link" :to="item.url">{{ item.title
                                                }}</router-link>
                                                <i class="fa fa-play" aria-hidden="true"></i>
                                                <ul class="three">

                                                    <li v-for="(it, i) in item.child" @mouseenter.stop="setClassImg(it.imgurl, index)" :key="i">
                                                        <router-link class="link" :to="it.url">{{ it.name }}</router-link>
                                                        <i class="fa fa-play" aria-hidden="true"></i>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div v-if="domData[index]" class="pic">
                                <transition name="fade" mode="out-in" enter-active-class="animate__fadeIn"
                                    :duration="{ enter: 300, leave: 300 }">
                                    <img class="img" :src="domData[index]?.activeImg" :key="domData[index]?.key" />
                                </transition>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

            <div :class="{ active: setDom.mittle, mittle: true }" v-on:click.stop="setMittle">
                <i class="fa fa-bars" aria-hidden="true"></i>
                <div class="items">
                    <div class="tel">
                        <div class="icon pic"></div>
                        <p>400-6801881</p>
                    </div>
                    <div class="search-conitioner" @mouseleave="blur">
                        <!-- <i class="fa fa-search" aria-hidden="true"></i> -->
                        <input type="text" v-model="getSearch" v-on:click.stop="" 
                            @keyup="setSearchValue">
                        <i class="fa fa-search" aria-hidden="true"></i>
                        <div ref="searchValue" class="search-value">
                            <ul class="">
                                <li v-for="(item, index) in searchValue" :key="index">
                                    <router-link :to="item.url">{{ item.name }}</router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="langs" @mouseleave="setLangTab">
                        <div @mouseenter="setLangTab" class="title">{{ this.lang }}<i class="fa fa-angle-down"
                                aria-hidden="true"></i>
                        </div>
                        <div class="select">
                            <ul :class="setDom.langTab ? 'active' : ''">
                                <li @click="setStateLang" v-for="(item, index) in langs" :data-lang="item" :key="index">
                                    {{ item }}
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>

            </div>

        </div>
        <div v-if="setDom.meidia.xs" class="content">
            <div class="logo pic">
                <router-link to="/">
                    <img src="../../assets/loag1.png" alt="">
                </router-link>
            </div>
            <div ref="xs-more" class="xs-more">
                <i class="fa fa-bars" @click="moreBtn" aria-hidden="true"></i>

                <div class="cont">
                    <div class="head">
                        <div class="search-conitioner" @keydown="setSearchValue" @mouseleave="blur">
                            <input type="text" v-model="getSearch" @keyup="setSearchValue" >
                            <i class="fa fa-search" aria-hidden="true"></i>
                            <div ref="searchValue" class="search-value">
                                <ul class="">
                                    <li v-for="(item, index) in searchValue" :key="index">
                                        <router-link :to="item.url">{{ item.name }}</router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="langs">
                            <div v-on:click.stop="setLangTab" class="title">{{ this.lang }}<i class="fa fa-angle-down"
                                    aria-hidden="true"></i></div>

                            <ul :class="setDom.langTab ? 'active' : ''">
                                <li v-on:click.stop="setStateLang" v-for="(item, index) in langs" :data-lang="item"
                                    :key="index">{{ item }}</li>
                            </ul>
                        </div>
                    </div>
                    <div ref="xs-body" class="body">
                        <div class="left">
                            <div style="color:#fff" class="classify">
                                <div class="links">

                                    <div style="color:#fff" v-for="(classItem, cIndex) in xsData?.data" :key="cIndex"
                                        class="classify-item">
                                        <div class="title">
                                            <router-link class="link" :to="classItem.url ? classItem.url : ''">
                                                {{ classItem.title ? classItem.title : '' }}</router-link>
                                            <ul class="two">
                                                <li v-for="(item, i) in classItem.child" :key="i">
                                                    <router-link class="two-link" :to="item.url">{{ item.title }}
                                                    </router-link>
                                                    <i class="fa fa-play" aria-hidden="true"></i>
                                                    <ul class="three">

                                                        <li v-for="(it, i) in item.child" :key="i">
                                                            <router-link class="link" :to="it.url">{{ it.name }}
                                                            </router-link>
                                                            <i class="fa fa-play" aria-hidden="true"></i>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="domData[index]" class="pic">
                                    <transition name="fade" mode="out-in" enter-active-class="animate__fadeIn"
                                        :duration="{ enter: 300, leave: 300 }">
                                        <img class="img" :src="domData[index]?.activeImg" :key="domData[index]?.key" />
                                    </transition>
                                </div>
                            </div>
                        </div>
                        <div class="right">
                            <ul>
                                <!-- @mousemove="" -->
                                <!-- <li v-for="(item,index) in langUse" @click="setXsNavAvtive($event,index)" 
                                        :class="active == item.key ? 'liActive' : ''" :key="index"> -->
                                <li v-for="(item, index) in langUse" @click="setXsNavAvtive($event, index)"
                                    ref="xs_more_li"
                                    :class="{ active: active == item.key, activeColor: active == item.key }"
                                    :key="index">
                                    <p :to="item.url">{{ item.name }}</p>

                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </nav>

</template>
<script>
import { mapState, mapMutations } from 'vuex'
import ajax from '@/ajax'
export default {
    props: ["isActive"],
    data() {
        return {
            setDom: {
                langTab: false,
                meidia: {
                    l: null,
                    m: null,
                    xs: null
                },
                mittle: false,
                xsDom: {}
            },
            domData: {
                ['0']: {
                    activeImg: require('../../assets/index/c1.png'),
                    key: 0,
                    data: [
                        {
                            title: "公司简介",
                            url: "",
                            imgurl: require('../../assets/nav/1.jpg')
                        },
                        {
                            title: "企业文化",
                            url: "",
                            imgurl: require('../../assets/nav/2.jpg')
                        },
                        {
                            title: "社会责任",
                            url: "",
                            imgurl: require('../../assets/nav/3.jpg')
                        },
                        {
                            title: "资质荣誉",
                            url: "",
                            imgurl: require('../../assets/nav/4.jpg')
                        },
                        {
                            title: "发展历程",
                            url: "",
                            imgurl: require('../../assets/nav/5.jpg')
                        }
                    ],
                },
                ["1"]: {
                    activeImg: require('../../assets/index/c1.png'),
                    key: 0,
                    data: [
                        {
                            child: [
                                {
                                    title: "饰能板",
                                    url: "",
                                    child: [
                                        {
                                            name: '澳思柏恩饰能板',
                                            url: '',
                                            imgurl: require('../../assets/index/c2.png')
                                        }
                                    ]
                                },
                                {
                                    title: "LSB系列",
                                    url: "",
                                    child: [
                                        {
                                            name: 'LSB高定专享板',
                                            url: '',
                                            imgurl: require('../../assets/index/c3.png')
                                        },
                                        {
                                            name: 'LSB高定专享板',
                                            url: '',
                                            imgurl: require('../../assets/index/c4.png')
                                        }, {
                                            name: 'LSB高能家居板',
                                            url: '',
                                            imgurl: require('../../assets/index/c1.png')
                                        }, {
                                            name: '锌效抗菌LSB高能家居板',
                                            url: '',
                                            imgurl: require('../../assets/index/c2.png')
                                        }
                                    ]
                                },
                                {
                                    title: "澳松板系列",
                                    url: "",
                                    child: [
                                        {
                                            name: '澳松板',
                                            url: '',
                                            imgurl: require('../../assets/index/c3.png')
                                        }, {
                                            name: '锌效抗菌澳松板',
                                            url: '',
                                            imgurl: require('../../assets/index/c4.png')
                                        }
                                    ]
                                },
                                {
                                    title: "OSB",
                                    url: "",
                                    child: [
                                        {
                                            name: '实木切片OSB',
                                            url: '',
                                            imgurl: require('../../assets/index/c1.png')
                                        },
                                        {
                                            name: '难燃OSB',
                                            url: '',
                                            imgurl: require('../../assets/index/c2.png')
                                        }
                                    ]
                                }
                            ]
                        }
                    ],

                },
                ["2"]: {
                    activeImg: require('../../assets/index/c1.png'),
                    key: 0,
                    data: [{
                        title: "智能制造",
                        url: "/advant/intel",
                        child: [{
                            title: "智能设备",
                            url: "/advant/intel",
                            child: [
                                {
                                    name: '德国帕尔曼长材刨片机',
                                    url: '',
                                    imgurl: require('../../assets/index/c2.png')
                                },
                                {
                                    name: '表芯层气流风选机',
                                    url: '',
                                    imgurl: require('../../assets/index/c2.png')
                                },
                                {
                                    name: '翻板冷却系统',
                                    url: '',
                                    imgurl: require('../../assets/index/c2.png')
                                },
                                {
                                    name: 'grecon在线厚度检测仪',
                                    url: '',
                                    imgurl: require('../../assets/index/c2.png')
                                }, {
                                    name: '德国EWK喷淋湿电一体化',
                                    url: '',
                                    imgurl: require('../../assets/index/c2.png')
                                }, {
                                    name: '意大利意玛帕尔三层超级筛',
                                    url: '',
                                    imgurl: require('../../assets/index/c2.png')
                                }, {
                                    name: '加拿大Coil滚筒式搅拌机',
                                    url: '',
                                    imgurl: require('../../assets/index/c2.png')
                                }, {
                                    name: '布特纳气流干燥机',
                                    url: '',
                                    imgurl: require('../../assets/index/c2.png')
                                }, {
                                    name: '德国辛贝尔康普热压机',
                                    url: '',
                                    imgurl: require('../../assets/index/c2.png')
                                }, {
                                    name: '加拿大Coil滚筒式搅拌机',
                                    url: '',
                                    imgurl: require('../../assets/index/c2.png')
                                }
                            ]
                        }]
                    }, {
                        title: "资源优势",
                        url: "/advant/reso",
                        child: [{
                            name:"原材优势",
                            url: "原材优势",
                            child: []
                        }]
                    }]
                },
                ['3']: {
                    activeImg: require('../../assets/index/c1.png'),
                    key: 0,
                    data: [
                        {
                            title: "日照工厂",
                            url: "",
                            imgurl: require('../../assets/index/c2.png')
                        },
                        {
                            title: "郯城工厂",
                            url: "",
                            imgurl: require('../../assets/index/c2.png')
                        },
                        {
                            title: "邳州工厂",
                            url: "",
                            imgurl: require('../../assets/index/c2.png')
                        }
                    ],
                },
                ['4']: {
                    activeImg: require('../../assets/index/c1.png'),
                    key: 0,
                    data: [
                        {
                            title: "八大支持",
                            url: "/coop/0",
                        },
                        {
                            title: "加盟流程",
                            url: "/coop/1",
                        },
                        {
                            title: "授权查询",
                            url: "/coop/2",
                        }
                    ],
                }
            },
            xsData: {},
            getSearch: "",
            searchValue: []
        }
    },
    computed: {
        ...mapState(["langPack", "lang", 'langs', 'baseUrl']),
        langUse() {
            return this.langPack[this.lang].navigation
        },
        active() {
            // return this.$route.name == key ? "liActive" : ""
            return this.$route.meta.active_key
        }
    },
    methods: {
        ...mapMutations(['setLang',]),
        // Dom操作
        // 更改语言Dom
        setLangTab() {
            this.setDom.langTab = !this.setDom.langTab
        },
        // // 更改语言
        setStateLang(e) {
            this.setLang(e.target.dataset.lang)
            this.setMittle();
            this.setLangTab();
        },
        // 更改
        setMittle() {
            if (document.body.clientWidth < 1441) {
                this.setDom.mittle = !this.setDom.mittle
            }
        },
        // 图片更改
        setClassImg(url, index) {
            // const imgurl = url
            console.log(url)
            if (url) {
                this.domData[index].activeImg = url
                this.domData[index].key++
            }

        },
        // 导航媒体查询
        navMedia() {
            let _this = this
            let widthFn = function () {
                if (document.body.clientWidth > 1441) {
                    _this.setDom.meidia.l = true
                    _this.setDom.meidia.m = false
                    _this.setDom.meidia.xs = false
                }
                if (document.body.clientWidth < 1441 && document.body.clientWidth > 1100) {
                    _this.setDom.meidia.l = false
                    _this.setDom.meidia.m = true
                    _this.setDom.meidia.xs = false
                }
                if (document.body.clientWidth < 1100) {
                    _this.setDom.meidia.l = false
                    _this.setDom.meidia.m = false
                    _this.setDom.meidia.xs = true
                }
            }
            widthFn();
            window.onresize = function () {
                widthFn();
            }
        },
        // 导航鼠标悬浮选中
        setNavAvtive: (function () {
            let time = 0
            let flag = {}
            return function (event, key) {
                const now = new Date().getTime();
                // let key = event.currentTarget.dataset.key
                // let key = event.currentTarget.dataset.key
                if (!flag[key]) {
                    flag[key] = {
                        element: event.currentTarget,
                        flag: false
                    }
                }

                for (const k in flag) {
                    if (k != key) {
                        flag[k].element.classList.remove("liActive")
                        flag[k].flag = false
                    }
                }

                if (flag[key].flag) {
                    if (now - time > 200) {
                        flag[key].element.classList.remove("liActive")
                        time = now
                    }
                } else {
                    flag[key].element.classList.add("liActive")
                }
                flag[key].flag = !flag[key].flag
            }
        })(),
        setNaveActiveHoverMove(event) {
            event.currentTarget.classList.add("liActive")
        },
        setRemoveAvtive(event) {
            event.currentTarget.classList.remove("liActive")
        },
        // 小屏幕栏目点击事件
        setXsNavAvtive(e, index) {
            this.xsData = this.domData[index];

            if (e != undefined) {
                this.$refs.xs_more_li.forEach(item => {
                    item.classList.remove("activeColor")
                })
                e.currentTarget.classList.add("activeColor")

            }
            if (!this.setDom.xsDom[index]) {
                if (!e) {
                    e = { currentTarget: document.querySelector(".xs-more .body .right ul li") }
                }
                this.setDom.xsDom[index] = e.currentTarget.children[0]
            }
            for (const key in this.setDom.xsDom) {
                const element = this.setDom.xsDom[key];
                element.style.color = "#fff"
            }
            if (this.xsData) {
                this.$refs['xs-body'].classList.add('xs-active')
            } else {
                this.$refs['xs-body'].classList.remove('xs-active')
            }

        },
        moreBtn: (function () {
            let flag = false
            return function (e) {
                if (flag) {
                    e.currentTarget.classList.add("fa-bars")
                    e.currentTarget.classList.remove("fa-times")
                    this.$refs['xs-more'].classList.remove('xs-active-more')
                } else {
                    e.currentTarget.classList.remove("fa-bars")
                    e.currentTarget.classList.add("fa-times")
                    this.$refs['xs-more'].classList.add('xs-active-more')
                    // 手机端触发造成首次导航显示
                    this.setXsNavAvtive(undefined, 0)
                }
                flag = !flag
            }
        })(),
        // 导航下拉框自适应位置
        navAutoPos: function () {
            // this.$refs.classify
            if (!this.$refs.classify) {
                return false
            }
            for (let index = 0; index < this.$refs.classify.length; index++) {
                const element = this.$refs.classify[index];

                // 元素位置初始化,防止宽度变化位置不准确
                element.style.left = 0
                element.style.right = 'auto'

                let viewWidth = document.body.clientWidth //屏幕宽度
                let { width, x } = element.getBoundingClientRect() //元素位置信息
                let chazhi = viewWidth - width - x //算出差值
                if (chazhi < 0 && x > 372) {
                    element.style.left = 'auto'
                    element.style.right = 0
                }
            }
        },
        setSearchValue() {
            let _this = this
            if (!this.getSearch.trim()) {
                _this.blur();
                return
            }
            ajax(this.baseUrl + 'web/associateproduct', { name: this.getSearch }).then((result) => {
                if(result.length >0){
                    _this.focus();
                }else{
                    _this.blur();
                }
                
                let arr = [];
                for (let index = 0; index < 8; index++) {
                    const item = result[index];
                    if (result.length > 0 && item) {
                        arr.push({
                            name: item.name,
                            url: '/brand/' + item.brid + '/' + item.id
                        })
                    }
                }
                _this.searchValue = arr

            })
        },
        // 服务器数据初始化
        init() {
            this.about();
            this.brand();
            this.core();
            this.plant();
        },
        about() {
            let _this = this
            let arr = []

            ajax(this.baseUrl + 'web/aboutbannerlistService').then(result => {
                result.forEach((item, index) => {
                    arr.push({
                        title: item.name,
                        url: '/about/' + index,
                        imgurl: _this.baseUrl + item.imgurl
                    })
                });
                _this.domData['0'].data = arr

            })
        },
        async brand() {
            let arr = [];
            let _this = this
            // 一级标题
            const brand_classify = await ajax(this.baseUrl + 'web/brandtypeService')
            for (let index = 0; index < brand_classify.length; index++) {
                const item = brand_classify[index];

                arr.push({
                    title: item.name,
                    url: '/brand/' + index,
                    imgurl: this.baseUrl + item.imgurl,
                    child: []
                })
                // 二级导航
                const brand_product = await ajax(this.baseUrl + 'web/brandproductService', { brid: item.id })
                // console.log(brand_product,"品牌产品名称")
                for (let i = 0; i < brand_product.length; i++) {
                    const it = brand_product[i];
                    if (!arr[arr.length - 1].child) {
                        arr.child = []
                    }
                    // 二级导航图片
                    const brand_product_img = await ajax(this.baseUrl + 'web/brandproductimgService', { brid: it.id })
                    console.log(brand_product_img,"品牌产品图片")
                    
                    let imgurl = null;
                    if(brand_product_img[0]){
                        imgurl = _this.baseUrl + brand_product_img[0].brandImg
                    }


                    arr[arr.length - 1].child.push({
                        name: it.name,
                        url: '/brand/' + item.id + '/' + it.id,
                        imgurl: imgurl,
                    })

                    console.log(arr)
                    // console.log(brand_product_img,"二级导航图片")
                    // if (brand_product_img.length) {
                    //     arr[arr.length - 1].child[arr[arr.length - 1].child.length - 1].imgurl = _this.baseUrl + brand_product_img[0].brandImg
                    // }

                }
            }


            _this.domData['1'].data[0].child = arr
        },
        core() {
            let _this = this
            ajax(this.baseUrl + 'web/devicelistService').then(result => {
                let arr = []
                result.forEach((item, index) => {
                    arr.push({
                        name: item.name,
                        // url: '/inte/'+index,
                        url: '/advant/intel/' + index,
                        imgurl: _this.baseUrl + item.imgurl,
                    })
                })
                _this.domData['2'].data[0].child[0].child = arr
            })
            ajax(this.baseUrl + 'web/advantageService').then((res) => {
                // let titarr = []
                _this.domData['2'].data[1].child = []
                res.forEach((item,i)=>{
                    _this.domData['2'].data[1].child.push({
                        title: item.name,
                        // url: '/inte/'+index,
                        url: '/advant',
                        imgurl:require('@/assets/core/b1.jpg'),
                        child:[]
                    })
                    ajax(this.baseUrl + 'web/advantagelistService',{adId:item.id}).then((result) => {
                        let arr = []
                        result.forEach((item, index) => {
                            arr.push({
                                name: item.name,
                                url: '/advant/reso/' + index,
                                imgurl: require('@/assets/core/b1.jpg')
                            })
                        })
                        _this.domData['2'].data[1].child[i].child = arr  // eslint-disable-line
                    })
                })
            })
            
        },
        plant() {
            let _this = this
            ajax(this.baseUrl + 'web/intelligentbriefService').then(result => {
                let arr = []
                result.forEach((item, index) => {

                    arr.push({
                        title: item.title,
                        url: '/plant/' + index,
                        imgurl: null
                    })

                    ajax(_this.baseUrl + 'web/intelligentlistService').then(result => {

                        _this.domData['3'].data[index].imgurl = this.baseUrl + result[0].imgurl
                    })
                })

                _this.domData['3'].data = arr
            })
        },
        // 获取焦点
        focus: function () {
            this.$refs.searchValue.style.opacity = 1
            this.$refs.searchValue.style.pointerEvents = 'auto'
        },
        blur: function () {
            this.$refs.searchValue.style.opacity = 0
            this.$refs.searchValue.style.pointerEvents = 'none'
        },
    },
    created() {
        this.init();
    },
    mounted() {
        // 导航长度变化 适应屏幕宽度
        this.navMedia();
        console.log(this.meidia)
        if (this.setDom.meidia.l || this.setDom.meidia.m) {
            console.log(this.$refs.classify)
            this.navAutoPos();
        }
        console.log(this.$route.meta.active_key)
        // 监听路由
    },

}
</script>
<style lang="less" scoped>
nav {
    // position: absolute;
    position: fixed;
    width: 100vw;
    max-width: 100vw;
    top: 0;
    z-index: 99999;
    height: 67px;
    // overflow-x: hidden;
    // overflow-y: auto;
}

.content {
    position: relative;
    padding: 0 20px;
    // padding-top: 15px;
    // padding-bottom: 12px;
    z-index: 9;
    display: flex;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    border-bottom: rgba(255, 255, 255, 0.2) 1px solid;
    height: 100%;

    // logo
    &>.logo {

        // width: 170px;
        // width: 160px;
        width: 140px;
        height: 52px;
        position: relative;
        z-index: 9;
        flex-shrink: 0;
        padding-top: 15px;


        img {
            display: block;
            object-fit: contain;
        }
    }

    // 导航链接
    .nav-links {
        flex: 1;
        height: 100%;
        transition: .7s;

        &>ul {
            display: flex;
            // margin-left: 17%;
            list-style: none;
            line-height: 1;
            height: 100%;
            width: fit-content;
            justify-content: space-between;
            min-width: 474px;
            opacity: 1;
            pointer-events: none;
            transform: translate(20px);
            pointer-events: auto;
            margin: auto;

            &.active {
                opacity: 1;
                transform: translate(0px);
                // flex: 1;
                justify-content: flex-end;
                align-content: center;
                align-items: center;
                // margin-right: 40px;
                margin-right: 2px;
            }

            &>li {
                margin-left: 20px;
                margin-right: 20px;
                height: 100%;
                position: relative;
                width: fit-content;

                &.activeColor {
                    a {
                        color: #b9985e;

                        &::before {
                            width: 100%;
                            background: #b9985e;
                            bottom: 0px;
                        }

                        &::after {
                            width: 100%;
                        }
                    }
                }

                .classify {
                    position: absolute;
                    width: 623px;
                    display: flex;
                    background: rgb(0, 84, 61);
                    margin-top: 67px;
                    opacity: 0;
                    transition: .3s;
                    pointer-events: none;

                    .classify-item {
                        margin-bottom: 23px;
                    }

                    .links {
                        width: 51%;
                        // padding: 30px;
                        padding: 28px 30px 30px 33px;
                        box-sizing: border-box;

                        .title>.link {
                            color: rgb(203, 246, 236);
                            font-size: 1.9rem;
                            line-height: 2;

                            &:hover {
                                color: #b9985e;
                            }
                        }

                        .two {
                            li {
                                list-style: none;

                                &:hover {
                                    color: #b9985e;
                                }

                                .two-link {
                                    color: rgb(203, 246, 236);
                                    font-size: 1.8rem;
                                    line-height: 2;

                                    &:hover {
                                        color: #b9985e;
                                    }
                                }

                                .three {
                                    color: rgb(131, 192, 176);
                                    //  list-style: ;
                                    margin-bottom: 10px;

                                    li {
                                        list-style: inside !important;

                                    }

                                    li:hover {
                                        color: #b9985e;

                                        .link {
                                            color: #b9985e;
                                        }

                                        .fa {
                                            color: #b9985e;
                                        }
                                    }

                                    .link {
                                        color: rgb(131, 192, 176);
                                        font-size: 1.4rem;
                                        line-height: 1.8;
                                        transition: .3s;
                                    }
                                }

                                .fa {
                                    font-size: 1.2rem;
                                    transform: scale(.5);
                                    float: right;
                                    color: transparent;
                                    transition: .7s;

                                }
                            }
                        }
                    }

                    .pic {
                        flex: 1;
                    }
                }
            }

            &>li {
                width: fit-content;
                min-width: calc(4 * 16px);
                // padding: 0 5px;
                // margin-top: 18px;
                // text-align: center;
                box-sizing: border-box;
                position: relative;

                // overflow: hidden;
                &:hover {
                    &>a {
                        &::before {
                            width: 100%;
                            background-color: #b9985e;

                        }
                    }
                }

                &:nth-child(1) {
                    // padding: 0 10px;
                    text-indent: 1rem;

                }

                &>a {
                    display: block;
                    color: #fff;
                    font-size: 1.6rem;
                    text-align: center;
                    line-height: 67px;
                    width: fit-content;
                    // text-justify: inter-cluster;
                    // position: relative;
                    // padding-bottom: 15px;

                    // position: absolute;
                    // top: calc(50% + 0.8rem);
                    // transform: translateY(-50%);


                    // &::after {
                    //     content: "";
                    //     display: inline-block;
                    //     width: 100%;
                    // }

                    &::before {
                        content: "";
                        display: block;
                        width: 0;
                        height: 3px;
                        // background: #b9985e;
                        background: transparent;
                        position: absolute;
                        left: 0;
                        bottom: 0px;
                        transition: .3s;
                        left: 50%;
                        transform: translate(-50%);
                    }
                }

                // }

            }

            &>li.liActive {
                height: 100%;

                .classify {
                    margin-top: 0px;
                    opacity: 1;
                    pointer-events: auto;

                }

                &>a {
                    &::before {
                        background: #b9985e;
                        width: 100%;

                    }
                }
            }

        }
    }


    .tel {
        display: flex;
        // margin-left: 14%;
        height: 100%;
        align-items: center;

        .pic {
            width: 20px;
            height: 22px;
            background: url(../../assets/icons_01.png) no-repeat 136px;
            background-size: 109px;
            background-position: -34px -25px;
            // margin-top: 15px;
            margin-left: -8px;
        }

        p {
            color: #fff;
            font-size: 1.8rem;
            // line-height: 3;
            // margin-top: 15px;
            letter-spacing: 1.3px;
            margin-left: 8px;
            position: relative;
            z-index: 1;
        }
    }

    .search-conitioner {
        margin-left: 4.4%;
        background-color: #fff;
        border-radius: 14px;
        height: 26px;
        width: 26px;
        // margin-top: 13px;
        position: relative;
        text-align: center;
        margin-right: 30px;
        transition: .7s;
        display: flex;
        justify-content: center;
        position: relative;
        // height: 100%;

        input {
            display: block;
            border: none;
            height: 25px;
            text-indent: 1em;
            outline: none;
            padding: 0;
            margin: 0;
            width: 0px;
        }

        .search-value {
            position: absolute;
            width: 100%;
            top: 100%;
            padding-top: 20px;
            pointer-events: none;
            opacity: 0;
            transition: .7s;

            ul {
                padding: 20px 0;
                border-radius: 8px;
                list-style: none;
                overflow: hidden;
                background: #00543d;
            }

            li {
                color: #fff;
                line-height: 2.5;
                text-align: left;
                cursor: pointer;
                text-indent: 2em;
                text-overflow: ellipsis;
                overflow: none;
                white-space: nowrap;

                a {
                    color: inherit;
                    font-size: 1.4rem;
                }

                // &:hover {
                //     background: #034b38;

                // }
            }
        }

        .fa {
            font-size: 1.8rem;
            line-height: 28px;
            color: #999999;
            text-align: center;
        }
    }

    .langs {
        // margin-top: 13px;
        position: relative;
        z-index: 99;


        // margin-left: 3%;
        .title {
            color: #999;
            font-size: 1.4rem;
            text-transform: uppercase;
            background: #fff;
            letter-spacing: 6px;
            padding: 5px 10px;
            border-radius: 30px;
            cursor: pointer;

            .fa {
                display: inline-block;
                font-size: 1.8rem;
                // line-height: 2;
                // height: 1.4rem;
            }

        }

        .select {
            position: absolute;
            left: 0;
            width: 100%;
            padding-top: 8px;
        }

        ul {
            display: block;
            width: 100%;
            list-style: none;
            margin-left: 0;
            background: #fff;
            border-radius: 15px;
            // margin-top: 8px;
            overflow: hidden;
            opacity: 0;
            pointer-events: none;
            transition: .3s;
            height: 0;

            &.active {
                opacity: 1;
                pointer-events: auto;

                height: 70px;


            }

            li {
                color: #999;
                font-size: 1.4rem;
                text-transform: uppercase;
                line-height: 35px;
                text-indent: 20px;
                letter-spacing: 3px;
                cursor: pointer;

                &:hover {
                    background: #ccc;

                }

            }
        }
    }

}

.mask {
    position: absolute;
    width: 100%;
    height: 50vh;
    left: 0;
    top: 0;
    z-index: 8;
    pointer-events: none;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

@media all and (max-width:1500px) {
    .content {
        .tel {
            margin-left: 0;
        }
    }

    .content>ul>li>a {
        font-size: 1.8rem;
    }
}

@media all and (max-width:1441px) {
    .content {
        .nav-links.active {
            transform: translate(-60px);
            opacity: 0;
            pointer-events: none;
        }

        .mittle {
            position: static;
            width: 20px;
            height: 20px;
            margin-top: 3px;


            &>.fa {
                font-size: 1.8rem;
                color: #fff;
                cursor: pointer;
            }

            .items {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 100%;
                display: flex;
                justify-content: flex-end;
                transition: right .7s;

                opacity: 0;
                right: 40px;
                pointer-events: none;

            }

            .tel {
                position: static;
                // display: none;
                right: 150px;
                width: fit-content;
                // top: -18px;
                margin-right: 0px;

                pointer-events: none;
            }

            .search-conitioner {
                position: static;
                pointer-events: none;
                margin: 0 30px;
            }

            .langs {
                width: fit-content;
                pointer-events: none;
                margin: 0 0 0 0px;
                position: relative;

            }

            &.active {
                .items {
                    opacity: 1;
                    right: 60px;

                    .tel,
                    .search-conitioner,
                    .langs {
                        pointer-events: auto;
                    }
                }

                // .tel,
                // .search-conitioner,
                // .langs {
                //     opacity: 1;
                //     pointer-events: auto;
                //     transform: translate(-0px);

                // }
            }
        }

        .mittle.active {}
    }
}

@media all and (max-width:1100px) {
    .content {
        &>ul {
            &.active {
                li {
                    margin-left: 20px;
                    margin-right: 20px;
                }
            }
        }

    }

    .xs-more {
        position: static;
        width: 20px;
        height: 20px;
        // background-color: #fff;
        // margin-top: 18px;

        &.xs-active-more {
            pointer-events: auto !important;

            .head {
                transform: translateY(0) !important;
                opacity: 1 !important;
                pointer-events: auto !important;

            }

            .body {
                transform: translateX(0%) !important;
                opacity: 1 !important;
                pointer-events: auto !important;

                .left {
                    width: 65%;
                    overflow-y: auto;
                }

            }
        }

        &>.fa {
            font-size: 2rem;
            color: #fff;
        }

        .cont {
            position: absolute;
            width: 100%;
            top: 100%;
            left: 0;
            height: 100vh;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            pointer-events: none !important;


            .head {
                width: 100%;
                display: flex;
                padding: 0 15px;
                box-sizing: border-box;
                background: #00543d;
                // padding-bottom: 30px;
                padding: 15px;
                opacity: 0;
                transform: translateY(-10%);
                pointer-events: none;
                transition: .7s;
                overflow: visible;



                .search-conitioner {
                    border-radius: 30px;
                    flex: 1;
                    text-align: right;
                    padding-right: 15px;
                }
            }

            .body {
                // padding-top: 15px;
                flex: 1;
                display: flex;
                // background: #fff;
                background: #fff;
                transition: all 0.7s cubic-bezier(0, 0.97, 0.36, 1.21);
                opacity: 0;
                transform: translateX(100%);
                pointer-events: none;
                position: relative;
                z-index: -1;

                &.xs-active {
                    transform: translateX(0) !important;

                    .left {
                        width: 65%;
                    }
                }

                ul {
                    list-style: none;
                }

                .left {
                    width: 50%;
                    order: 2;
                    background: #027255;

                    .classify {
                        position: static;
                        width: 100%;
                    }

                    .classify {

                        .classify-item {
                            margin-bottom: 23px;
                        }

                        .links {
                            width: 100%;
                            // padding: 30px;
                            padding: 13px 30px 30px 33px;
                            box-sizing: border-box;

                            .title>.link {
                                color: rgb(203, 246, 236);
                                font-size: 1.9rem;
                                line-height: 2;
                                // color: #b9985e;
                            }

                            .two {
                                li {
                                    list-style: none;

                                    .two-link {
                                        color: rgb(203, 246, 236);
                                        font-size: 1.8rem;
                                        line-height: 2;

                                        &:hover {
                                            color: #b9985e;
                                        }
                                    }

                                    .three {
                                        list-style: inside;
                                        color: rgb(131, 192, 176);

                                        li {
                                            list-style: inside;
                                            line-height: 40px;
                                        }

                                        li:hover {
                                            color: #b9985e;

                                            .link {
                                                color: #b9985e;
                                            }

                                            .fa {
                                                color: #b9985e;
                                            }
                                        }

                                        .link {
                                            color: rgb(131, 192, 176);
                                            font-size: 1.4rem;
                                            line-height: 2.6;
                                            transition: .3s;
                                        }
                                    }

                                    .fa {
                                        font-size: 1.2rem;
                                        transform: scale(.5);
                                        float: right;
                                        color: transparent;
                                        transition: .7s;

                                    }
                                }
                            }
                        }

                        .pic {
                            flex: 1;
                        }
                    }
                }

                .right {
                    // width: 50%;
                    flex: 1;
                    order: 1;
                    background: #00543d;

                    ul {
                        li {
                            text-align: center;
                            font-size: 1.6rem;
                            line-height: 60px;

                            p {
                                color: #fff
                            }
                        }
                    }
                }
            }
        }
    }
}

@media all and (max-width:1100px) {
    .content {
        padding: 10px;

        &>.pic {
            // 产品经理建议
            width: 140px;
            // 设计建议
            // 老板建议

        }
    }

    .content {
        .xs-more {
            width: 35px;
            height: 20px;

            // margin-top: 12px;
            &>.fa {
                font-size: 3rem;
            }
        }
    }

    .content {
        .xs-more {
            .cont {
                height: calc(100vh - 67px);
                overflow: hidden;

                .head {
                    .search-conitioner {
                        display: flex;
                        padding-left: 15px;
                        justify-content: space-between;

                        .search-value {
                            pointer-events: none;
                        }
                    }

                    input {
                        // margin-top: 1px;
                        display: block;
                        width: auto;
                    }
                }

                .body {
                    height: 100%;

                    .left {
                        width: 65%;
                        height: 100%;

                        .classify {
                            height: 83%;
                            overflow-y: auto;

                            .links {
                                padding: 15px;

                                .three {
                                    li {
                                        list-style: none !important;
                                        line-height: 2 !important;
                                        padding-left: 15px;
                                        position: relative;

                                        &::before {
                                            display: block;
                                            content: "";
                                            width: 2px;
                                            height: 2px;
                                            border-radius: 50%;
                                            background: #83c0b0;
                                            position: absolute;
                                            left: 7px;
                                            top: calc(1.4rem * 2.6 / 2);
                                        }
                                    }
                                }

                            }
                        }
                    }

                    .right {
                        .activeColor {
                            background-color: #027255;
                        }
                    }
                }
            }
        }
    }
}

@media all and (max-width:960px) {}

@media all and (max-width:769px) {
    .content{
        .langs{
            position: relative;
            &>ul{
                position: absolute;
            }
        }
    }
    .xs-more {
        .cont {
            .body {
                .right {
                    ul {
                        li {
                            height: 60px;
                            position: relative;
                            p {
                                line-height: 1.2;
                                position: absolute;
                                left: 50%;
                                top: 50%;
                                transform: translate(-50%,-50%);
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}



@media (any-hover: hover) {

    .search-conitioner:hover {
        width: 186px;

        input {
            width: 130px;
            transition: .7s;
        }

        .fa {
            margin-left: 8px;
        }

        //     .search-value {
        //         opacity: 1;
        //         pointer-events: auto;
        //     }
    }

}
</style>