import { createStore } from 'vuex'

import cn from '../lang/cn'
import en from '../lang/en'

export default createStore({
  state: {
    baseUrl:"http://121.89.216.1:18061/ausbourne-admin/",
    lang:"cn",
    langs:['cn','en'],
    langPack:{cn,en},
    videoOptions:{
      isActive:false,
      videoUrl:""
    },
    brand:undefined,
    core:null,
    adv:null
  },
  getters: {
  },
  mutations: {
    setLang(state,key){
      state.lang = key
      console.log(key)
    },
    setVideo(state,obj){
      // state.videoOptions.isActive = !state.videoOptions.isActive 
      console.log(obj)
      if(!obj.videoUrl){
        console.log("111")
        state.videoOptions.isActive = !state.videoOptions.isActive 

        // state.videoOptions.isActive = false
        return
      }
      state.videoOptions.videoUrl= obj.videoUrl;
      state.videoOptions.isActive = true
      console.log(state.videoOptions)


    },
    setBrand(state,obj){
      state.brand = obj
    },
    setCore(state,obj){
      state.core = obj
    },
    setAdv(state,obj){
      state.brand = obj
    },
    setBaseUrl(state,url){
      state.baseUrl = url
    }
  },
  actions: {
  },
  modules: {
  }
})
