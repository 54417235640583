<template>
    <footer>
        <div class="links-conition">
            <div class="links wow animate__fadeInUp" v-for="(item,index) in topData"  :key="index" :style="'height:' + links[index].h + 'px'">
                <h4 @click="linkShow($event,item.links.length,index)">{{item.title}}</h4>
                <div class="puls" ref="rotate" @click="linkShow($event,item.links.length,index)"></div>
                <ul>
                    <li v-for="(link,i) in item.links" :ref="'l'+index" class="animate__fadeOutLeft" :key="i"><router-link :to="link.url">{{link.name}}</router-link></li>
                </ul>
            </div>
        </div>
        <div class="buttons ">
            <router-link class="jiameng wow animate__fadeInLeft" to="/">申请加盟</router-link>
            <div class="line"></div>
            <router-link class="jiameng wow animate__fadeInRight" to="/">投递简历</router-link>
        </div>
        <div class="menu wow animate__fadeInUp">
            <ul>
                <li>
                    <div class="pic icon1"><img src="../../assets/icons_01.png" alt=""></div>
                </li>
                <li>
                    <div class="pic icon2"><img src="../../assets/icons_01.png" alt=""></div>
                </li>
                <li>
                    <div class="pic icon3"><img src="../../assets/icons_01.png" alt=""></div>
                </li>
                <li>
                    <div class="pic icon4"><img src="../../assets/icons_01.png" alt=""></div>
                </li>
                <li>
                    <div class="pic icon5"><img src="../../assets/icons_01.png" alt=""></div>
                </li>
            </ul>
        </div>
        <div class="beian wow animate__fadeInUp">
            <p class="beian-cont">
                    {{langUse.footer.index.beian}}
                    <br/>
                    Website design : <a href="manpinvip.com">满品传媒</a>
                    <br/>
                    <a href="https://beian.miit.gov.cn/">{{langUse.footer.index.beianhao}}</a>
            </p>
        </div>
    </footer>
</template>
<script>

export default {
    props:["topData"],
    data(){
        return {
            links :{}
        }
    },
    computed:{
        langUse() {
            return this.$store.state.langPack[this.$store.state.lang]
        }
    },
    methods:{
        linkShow(e,num,index){
            console.log(this.$refs['l'+index])
            let item = this.links[index]
            let i = 0
            let _this = this
            let time = 1000 / num
            console.log(time)
            e = e.currentTarget
            if(item.flag){
                console.log(this.$refs)
                this.$refs.rotate[index].classList.remove('rotateR')
                this.$refs.rotate[index].classList.add('rotate')
                let timer = setInterval(function(){
                
                    if(_this.$refs['l'+index][i]){
                        _this.$refs['l'+index][i].classList.remove("animate__fadeOutLeft")
                        _this.$refs['l'+index][i].classList.add("animate__fadeInLeft")
                    }else{
                        clearInterval(timer)
                    }
                    i++
                },time)

                this.links[index].h = 40 + 30 * num

            }else{
                this.$refs.rotate[index].classList.remove('rotate')
                this.$refs.rotate[index].classList.add('rotateR')
                
                // 记录展开高度
                // let h = (this.links[index].h - 40) / num

                i = num-1
                let timer = setInterval(function(){
                    if(_this.$refs['l'+index][i]){
                        _this.$refs['l'+index][i].classList.remove("animate__fadeInLeft")
                        _this.$refs['l'+index][i].classList.add("animate__fadeOutLeft") 
                    }else{
                        clearInterval(timer)
                    }
                    i--
                },time / 2)
                setTimeout(function(){
                    _this.links[index].h = 40
                },1000)

                //  = 40
            }
            item.flag = !item.flag
        }
    },
    beforeMount:function(){
        this.topData.forEach((item,index)=>{
            this.links[index] = {
                h:40,
                flag:true
            }
        })
    }
}
</script>
<style lang="less" scoped>

    footer{
        width: 100%;
        // height: 100vh;
        // background: url(../../assets/demo/20221031142821.png);
        background-image: url(../../assets/index/f1.png);
        background-size: 100%;
        box-sizing: border-box;
        padding: 37px 30px;

        .links-conition{
            width: 100%;
            
            .links{
                line-height: 40px;
                display: flex;
                flex-wrap: wrap;
                border-bottom: 1px #ffffff38 solid;
                margin-bottom: 12px;
                overflow: hidden;
                transition: .7s;
                transition-timing-function: cubic-bezier(0, 1.14, 1, 1);

                h4{
                    width: 90%;
                    font-size: 1.8rem;
                    color: #fff;
                }
                .puls{
                    font-size: 1.8rem;
                    flex: 1;
                    line-height: 40px;
                    color: #fff;
                    text-align: center;
                    position: relative;
                    &::before{
                        content: "";
                        display: block;
                        width: 1.4rem;
                        height: 2px;
                        background-color: rgb(255, 255, 255);
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                    }
                    &::after{
                        content: "";
                        display: block;
                        width: 2px;
                        height: 1.4rem;
                        background-color: rgb(255, 255, 255);
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                    }
                }
                ul{
                    display: block;
                    width: 100%;
                    margin-left: 20px;
                    // text-decoration: ;
                    li{
                        color: #fff;
                        line-height: 30px;
                        a{
                            color: #fff;
                            font-size: 1.4rem;
                        }
                    }
                }
            }
            
        }
        .buttons{
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 65px;
            .jiameng{
                color: #fff;
                font-size: 2.2rem;
                line-height: 40px;
            }
            .line{
                height: 2.2rem;
                width: 1px;
                margin: 0 20px;
                background-color: #fff;
            }
        }

        .menu{
            width: 100%;
            padding:0 40px;
            box-sizing: border-box;
            margin-top: 60px;

            ul{
                display: flex;
                justify-content: space-between;
                width: 100%;
                li{
                    width: 20px;
                    height: 20px;
                    // background: #fff;
                    list-style: none;
                    margin-top: 15.4px;
                    // opacity: .5;
                    .pic{
                        width: 100%;
                        height: 100%;
                        background-image: url(../../assets/icons_01.png);
                        filter: brightness(10);
                        transition: .3s;
                        cursor: pointer;
                        &:hover{
                            filter: brightness(1);

                        }
                        img{
                            display: none;
                        }
                    }
                    .icon1{
                        background-size: 136px;
                        background-position: -93px -141px;
                    }
                    .icon2{
                        width: 18px;
                        background-size: 136px;
                        background-position: 0px 0px;
                    }
                    .icon3{
                        background-size: 136px;
                        background-position: -17px 1px;
                    }
                    .icon4{
                        background-size: 136px;
                        background-position: -43px 3px;
                    }
                    .icon5{
                        background-size: 136px;
                        background-position: -66px 3px;
                    }
                }
            }   
        }
        .beian{
            margin-top: 60px;
            .beian-cont{
                text-align: center;
                color: #fff;
                font-size: 1.4rem;
                a{
                    color: #fff;
                    
                }
            }
        }
    }
    .rotate{
        animation-name: rotate;
    }
    .rotateR{
        animation-name: rotateR;
    }
    @keyframes rotate {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(135deg);
        }
    }
    @keyframes rotateR {
        0% {
            transform: rotate(135deg);
        }
        100% {
            transform: rotate(0deg);
        }
    }
</style>