<template>
    <router-link :to="url">Read More <i class="fa fa-long-arrow-right" aria-hidden="true"></i></router-link>
</template>
    
<script>
export default {
    props:["url"],
    setup(props) {
        console.log(props)
    },
}
</script>
<style lang="less" scoped>
    a{
        color: #333;
        font-size: 1.8rem;
        .fa{
            margin-left: 60px;
            transition: .8s;
        }
    }
    @media (any-hover: hover) {
        a:hover{
            .fa{
                margin-left: 80px;
            }
        }
    }
</style>