import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueLazyload  from 'vue-lazyload'
import 'babel-polyfill'

import {getBrowser} from './js/getBrowser'

// import 'core-js/stable'
// import 'regenerator-runtime/runtime'

// import Es6Promise from 'es6-promise'
// require('es6-promise').polyfill()
// Es6Promise.polyfill()
console.log(getBrowser())

createApp(App).use(store).use(router).use(VueLazyload,{
    // loading:require('./assets/loading.gif')
}).mount('#app')
