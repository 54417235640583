// import axios from 'axios'
/* eslint-disable */
import axios from 'axios'
// axios 封装
export default function(url,data={},method="GET"){
    //  路径空值
    if(!url){
        return console.error('路径为空')
    }
    
    let baseUrl = '';
    // 路径可直接请求不需要处理
    if(url.search("http" == -1)){
        baseUrl = ""
    }
    let dataStr = '';
    
    
    return new Promise((resolve,reject)=>{
        if(method == 'GET'){
            let suffix ; // 后缀
            if(JSON.stringify(data) != "{}"){
                for (const key in data) {
                    dataStr += `${key}=${data[key]}&`
                }
                dataStr = dataStr.substring(0,dataStr.length-1)
                console.warn(baseUrl + url +"?" + dataStr)
                suffix = url +"?" + dataStr
            }else {
                suffix = url
            }
            
            
            axios(baseUrl + suffix).then(res=>{
                resolve(res.data[0])
            }).catch(error=>{
                reject(error)
            })
        }else if(method == 'POST'){
            axios({
                method: 'post',
                url: baseUrl + url,
                data: data
            })
            .then(res=>{
                resolve(res.data.data)
            })
            .catch(error=>{
                reject(error)
            })
        }
    })
}

// export default function(url,data={},method="GET"){

//     return new Promise((resolve,reject)=>{
//         var baseUrl = 'https://api.muxiaoguo.cn/api/';
//         var dataStr = '';
//         for (const key in data) {
//             dataStr += `${key}=${data[key]}&`
//         }
//         dataStr = dataStr.substring(0,dataStr.length-1)
//         var xmlhttp;
//         if (window.XMLHttpRequest)
//         {
//             //  IE7+, Firefox, Chrome, Opera, Safari 浏览器执行代码
//             xmlhttp=new XMLHttpRequest();
//         }
//         else
//         {
//             // IE6, IE5 浏览器执行代码
//             xmlhttp=new ActiveXObject("Microsoft.XMLHTTP");
//         }
//         xmlhttp.onreadystatechange=function()
//         {
//             if (xmlhttp.readyState==4 && xmlhttp.status==200)
//             {
//                 console.log(JSON.parse(xmlhttp.responseText));
//                 // JSON.parse(xmlhttp.responseText)
//                 resolve(JSON.parse(xmlhttp.responseText))
                
//             }else{
//                 reject(JSON.parse(xmlhttp.responseText));
//             }
//         }
//         xmlhttp.open("GET",baseUrl + url +"?" + dataStr,method);
//         xmlhttp.send();
//     })

    
// }