export default {
    navigation:[
        // {
        //   name:"首页",
        //   url:"/",
        //   key:'home'
        // },
        {
          name:"关于我们",
          url:"/about",
          key:'about'
        },
        {
          name:"品牌产品",
          url:"/brand",
          key:'brand'
        },
        {
          name:"核心优势",
          url:"/advant",
          key:"advant"
        },
        {
          name:"智能工厂",
          url:"/plant",
          key:"plant",
          
        },
        {
          name:"招商合作",
          url:"/coop",
          key:'coop'
        },
        
    ],
    footer:{
        index:{
            gzh:"扫一扫关注公众号",
            wx:"微信联系客服",
            beian:"Copyright © 2018 日照市澳思柏恩装饰材料有限公司 版权所有        ",
            beianhao:"备案号：浙ICP备18027908号-3",
            friendLink:{
                title:"友情链接",
                links:[{
                    name:"千年舟集团官网",
                    links:"three.com"
                }]
            }
        },
        links: [
          {
              title: '媒体中心',
              links: [{
                  name: '工厂动态',
                  url: '/news/0'
              }, {
                  name: '产品动态',
                  url: '/news/1'
              }, {
                  name: '新闻动态',
                  url: '/news/2'
              }, {
                  name: '视频中心',
                  url: '/video/3'
              }]
          }, {
              title: '加入我们',
              links: [{
                  name: '人才招聘',
                  url: '/add'
              }, {
                  name: '企业文化',
                  url: '/add'
              }]
          }, {
              title: '联系我们',
              links: [{
                  name: '联系电话',
                  url: '/content'
              }, {
                  name: '公司地址',
                  url: '/content'
              }, {
                  name: '微信',
                  url: '/content'
              }]
          }
        ],
        bottomData: [{
            title: "申请成为我们的合作伙伴",
            desc: "我们致力于提供令客户满意的世界级的产品和服务。",
            btnName: "申请加盟"
        }, {
            title: "申请成为我们的合作伙伴",
            desc: "拥有思维开拓、经营管理、销售技能、精益管理等众多课程等您来学习！",
            btnName: "提交简历"
        }]
    },
    coop:{
        search:{
            input:"输入授权序列号或公司名称",
            button:"查询授权",
            table:{
                id:"序列号",
                conpany:"公司名称",
                date:"授权时间",
                begave:"操作",
                selct:"查看证书"
            }
        }
    },
    news:{
        more:"点击查看更多"
    },
    newsDetail:{
        next:"上一篇",
        prev:"下一篇",
        back:"返回新闻列表"
    },
    content:{
        title1:"联系方式",
        title1Desc:"我们始终关注您内心深处的需求，不断突破，交出令您惊喜和意外的作品。如果您有需要，请与我们联系！",
        title2:"澳思柏恩 · 服务热线",
        qr:"扫一扫关注我们！",
        center:"总部：",
        adds:"地址：",
        email:"邮箱：",
        coordinate:"坐标：",
        tel:"电话：",
        canyAdds:"公司地址"
    },
    add:{
        title:"申请加盟",
        info:"我们致力于提供令客户满意的世界级的产品和服务。申请成为我们的合作伙伴吧！<br />如果您有需要，请与我们联系，我们会尽快回复您的申请，请您放心填写，您的资料信息会保密处理！",
        product:"产品意向*",
        name:"您的姓名",
        iptName :"请输入文字",
        tel:"联系电话*",
        iptTel:"请输入数字",
        adds:"联系地址*",
        iptAdds:"请输入文字",
        idear:"请输入您的想法",
        btnText:"提交申请",
        // 工作
        jobTitle:"最新招聘",
        jobBtnText:"点击查看更多招聘",
        jobemail:"简历投至邮箱"
    }
}