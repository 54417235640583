<template>
    <div>
        <footer v-if="false" class="footer">
            <div class="left">
                <div class="top">
                    <div class="links wow animate__fadeInLeft" v-for="(item, index) in topData" :key="index">
                        <div class="title">{{ item.title }}</div>
                        <ul class="link">
                            <li v-for="(link, index) in item.links" :key="index">
                                <router-link :to="link.url">{{ link.name }}</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="bottom">
                    <div class="friend wow animate__fadeInLeft" v-for="(item, index) in bottomData" :key="index">
                        <p class="title">{{ item.title }}</p>
                        <p class="desc">{{ item.desc }}</p>
                        <div class="btn">
                            <router-link to="/add">{{ item.btnName }}</router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right">
                <h1 class="name-cn wow animate__fadeInUp" style="animation-delay:1s;">{{ rightData.commpanyCn }}</h1>
                <h1 class="name-en wow animate__fadeInUp" style="animation-delay:1s;">{{ rightData.commpanyEn }}</h1>
                <div class="adds wow animate__fadeInUp">
                    <div class="title">
                        <div class="icon pic"><img src="../../assets/icons_01.png" alt=""></div><span> Adds</span>
                    </div>
                    <ul>
                        <li v-for="(item, index) in rightData.adds" :key="index" :alt="item">{{ item }}</li>
                    </ul>
                </div>
                <div class="tel adds wow animate__fadeInUp">
                    <div class="title">
                        <div class="icon pic"><img src="" alt=""></div> <span>Tel</span>
                    </div>
                    <p class="cont">{{ rightData.tel }}</p>
                </div>
                <div v-if=false class="fax adds wow animate__fadeInUp">
                    <div class="title">
                        <div class="icon pic"><img src="" alt=""></div> <span>Fax</span>
                    </div>
                    <p class="cont">{{ rightData.fax }}</p>
                </div>
                <div class="clear"></div>
                <div class="qrs wow animate__fadeInUp">
                    <div class="gzh">
                        <div class="gzh-qr pic"><img v-lazy="rightData.gzh" alt=""></div>
                        <p>{{ langUse.footer.index.gzh }}</p>
                    </div>
                    <div class="wx">
                        <div class="gzh-qr pic"><img v-lazy="rightData.wx" alt=""></div>
                        <p>{{ langUse.footer.index.wx }}</p>
                    </div>
                </div>
            </div>
            <div class="beian wow animate__fadeInUp">
                <div class="left">
                    <p class="beian-cont">
                        {{ langUse.footer.index.beian }}<a
                            href="https://beian.miit.gov.cn/">{{ langUse.footer.index.beianhao }}</a>
                    </p>
                    <div class="zhichi">
                        Website design : <a href="manpinvip.com">满品传媒</a>
                    </div>
                    <div class="links">
                        <span>{{ langUse.footer.index.friendLink.title }}:</span>
                        <a v-for="(item, index) in langUse.footer.index.friendLink.links" :key="index"
                            :href="item.url">{{ item.name }}</a>
                    </div>
                </div>
                <div class="right">
                    <div class="menu">
                        <ul>
                            <li>
                                <div class="pic icon1"><img src="../../assets/icons_01.png" alt=""></div>
                            </li>
                            <li>
                                <div class="pic icon2"><img src="../../assets/icons_01.png" alt=""></div>
                            </li>
                            <li>
                                <div class="pic icon3"><img src="../../assets/icons_01.png" alt=""></div>
                            </li>
                            <li>
                                <div class="pic icon4"><img src="../../assets/icons_01.png" alt=""></div>
                            </li>
                            <li>
                                <div class="pic icon5"><img src="../../assets/icons_01.png" alt=""></div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
        <MfiveEle v-if="page.isM" :topData="topData"></MfiveEle>
        <footer v-if="!page.isM" class="footer">
            <div class="left">
                <div class="top">
                    <div :class="isActive ? 'links animate__fadeInLeft' : 'links'"
                        :style="'animation-delay:1.' + (3 - index) + 's;'" v-for="(item, index) in footer.links" :key="index">
                        <div class="title">{{ item.title }}</div>
                        <ul class="link">
                            <li v-for="(link, index) in item.links" :key="index">
                                <router-link :to="link.url">{{ link.name }}</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="bottom">
                    <div :class="isActive ? 'friend animate__fadeInLeft' : 'friend'"
                        :style="'animation-delay:1.' + (2 - index) + 's;'" v-for="(item, index) in footer.bottomData" :key="index">
                        <p class="title">{{ item.title }}</p>
                        <p class="desc">{{ item.desc }}</p>
                        <div class="btn"><router-link to="/add">{{ item.btnName }}</router-link></div>
                    </div>
                </div>
            </div>
            <div class="right">
                <h1 :class="isActive ? 'name-cn animate__fadeInUp' : 'name-cn'" style="animation-delay:1s;">
                    {{ rightData.commpanyCn }}</h1>
                <h1 :class="isActive ? 'name-en animate__fadeInUp' : 'name-en'" style="animation-delay:1s;">
                    {{ rightData.commpanyEn }}</h1>
                <div :class="isActive ? 'adds animate__fadeInUp' : 'adds'" style="animation-delay:1.2s;">
                    <div class="title">
                        <div class="icon pic"><img src="../../assets/icons_01.png" alt=""></div><span> Adds</span>
                    </div>
                    <ul>
                        <li v-for="(item, index) in rightData.adds" :key="index" :alt="item">{{ item }}</li>
                    </ul>
                </div>
                <div :class="isActive ? 'tel adds animate__fadeInUp' : 'tel adds'" style="animation-delay:1.4s;">
                    <div class="title">
                        <div class="icon pic"><img src="" alt=""></div> <span>Tel</span>
                    </div>
                    <p class="cont">{{ rightData.tel }}</p>
                </div>
                <div v-if="false" :class="isActive ? 'fax adds animate__fadeInUp' : 'fax adds'"
                    style="animation-delay:1.4s;">
                    <div class="title">
                        <div class="icon pic"><img src="" alt=""></div> <span>Fax</span>
                    </div>
                    <p class="cont">{{ rightData.fax }}</p>
                </div>
                <div class="clear"></div>
                <div :class="isActive ? 'qrs animate__fadeInUp' : 'qrs'" style="animation-delay:1.6s;">
                    <div class="gzh">
                        <div class="gzh-qr pic"><img v-lazy="rightData.gzh" alt=""></div>
                        <p>{{ langUse.footer.index.gzh }}</p>
                    </div>
                    <div class="wx">
                        <div class="gzh-qr pic"><img v-lazy="rightData.wx" alt=""></div>
                        <p>{{ langUse.footer.index.wx }}</p>
                    </div>
                </div>
            </div>
            <div :class="isActive ? 'beian animate__fadeInUp' : 'beian'" style="animation-delay:1s;">
                <div class="left">
                    <p class="beian-cont">
                        {{ langUse.footer.index.beian }}<a
                            href="https://beian.miit.gov.cn/">{{ langUse.footer.index.beianhao }}</a>
                    </p>
                    <div class="zhichi">
                        Website design : <a href="manpinvip.com">满品传媒</a>
                    </div>
                    <div class="links">
                        <span>{{ langUse.footer.index.friendLink.title }}:</span>
                        <a v-for="(item, index) in langUse.footer.index.friendLink.links" :key="index"
                            :href="item.url">{{ item.name }}</a>
                    </div>
                </div>
                <div class="right">
                    <div class="menu">
                        <ul>
                            <li>
                                <div class="pic icon1"><img src="../../assets/icons_01.png" alt=""></div>
                            </li>
                            <li>
                                <div class="pic icon2"><img src="../../assets/icons_01.png" alt=""></div>
                            </li>
                            <li>
                                <div class="pic icon3"><img src="../../assets/icons_01.png" alt=""></div>
                            </li>
                            <li>
                                <div class="pic icon4"><img src="../../assets/icons_01.png" alt=""></div>
                            </li>
                            <li>
                                <div class="pic icon5"><img src="../../assets/icons_01.png" alt=""></div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    </div>

</template>
<script>
import ajax from '@/ajax'
import MfiveEle from './MfiveEle.vue'
import { mapState } from 'vuex'

// import {mapState} from 'vuex'
// import wow from 'wowjs'
export default {
    props: ["isActive"],
    data() {
        return {
            page: {
                isM: true
            },
            topData: [
                {
                    title: '媒体中心',
                    links: [{
                        name: '工厂动态',
                        url: '/news/0'
                    }, {
                        name: '产品动态',
                        url: '/news/1'
                    }, {
                        name: '新闻动态',
                        url: '/news/2'
                    }, {
                        name: '视频中心',
                        url: '/video'
                    }]
                }, {
                    title: '加入我们',
                    links: [{
                        name: '人才招聘',
                        url: '/add/0'
                    }, {
                        name: '企业文化',
                        url: '/add/1'
                    }]
                }, {
                    title: '联系我们',
                    links: [{
                        name: '联系电话',
                        url: '/content'
                    }, {
                        name: '公司地址',
                        url: '/content'
                    }, {
                        name: '微信',
                        url: '/content'
                    }]
                }
            ],
            bottomData: [{
                title: "申请成为我们的合作伙伴",
                desc: "我们致力于提供令客户满意的世界级的产品和服务。",
                btnName: "申请加盟"
            }, {
                title: "申请成为我们的合作伙伴",
                desc: "拥有思维开拓、经营管理、销售技能、精益管理等众多课程等您来学习！",
                btnName: "提交简历"
            }],
            rightData: {
                commpanyCn: "澳思柏恩装饰材料有限公司",
                commpanyEn: "Osborne Decoration Materials Co., Ltd",
                adds: ["总部: 澳思柏恩装饰材料有限公司", "地址: 浙江省杭州市余杭区良渚街道好运街152号"],
                tel: "400-6801881",
                fax: "0633-2667333",
                gzh: require('../../assets/index/f2.png'),
                wx: require('../../assets/index/f3.png')
            },
            beian: {
                link: [],
                icp: []
            }
        }
    },
    components: {
        MfiveEle
    },
    created() {
        let _this = this
        ajax(_this.baseUrl + 'web/bottommessageService').then(result => {
            console.log(result, 123456)

            let item = result[result.length - 1]
            _this.rightData = {
                commpanyCn: item.name,
                commpanyEn: item.ename,
                adds: ["总部: " + item.headquarters, "地址: " + item.adds],
                tel: item.tel,
                fax: item.fax,
                gzh: _this.baseUrl + item.tencent,
                wx: _this.baseUrl + item.weChat
            }
            _this.beian = {
                link: [{
                    name: item.link,
                    url: item.link
                }],
                icp: item.icp
            }
        })
    },
    computed: {
        // ...mapState["lang","langPack"],
        ...mapState(["baseUrl",]),
        langUse() {
            return this.$store.state.langPack[this.$store.state.lang]
        },
        footer() {
            return this.$store.state.langPack[this.$store.state.lang].footer
        }
    },
    mounted() {
        let _this = this
        // 页面初次进入加载
        if (document.body.clientWidth > 769) {
            this.page.isM = false
        }
        // 宽度改变切换
        window.onresize = function () {
            if (document.body.clientWidth > 769) {
                _this.page.isM = false
            } else {
                _this.page.isM = true
            }
        }
    },
}
</script>
<style lang="less" scoped>
.footer {
    width: 100%;
    height: 100vh;
    background-image: url(../../assets/index/f1.png);
    background-size: 100%;
    padding-top: 67px;
    box-sizing: border-box;
    display: flex;
    overflow: hidden;
    flex-wrap: wrap;

    a {
        transition: .3s;
        cursor: pointer;
        outline: none;
        -webkit-tap-highlight-color: transparent;
    }

    &>.left,
    &>.right {
        // border-top: #407b6b 1px solid;
        border-bottom: #407b6b 1px solid;
    }

    &>.left {
        width: 61.4%;

        .top {
            padding-top: 6.5%;
            padding-left: 13.1%;
            padding-right: 13.1%;
            display: flex;
            border-bottom: #407b6b 1px solid;
            padding-bottom: 30px;

            .links {
                flex: 1;

                .title {
                    line-height: 1;
                    font-size: 2.4rem;
                    color: #fff;
                    margin-bottom: calc((1.6rem * 2.5) + 7px);

                }

                .link {
                    list-style: none;

                    li {

                        a {
                            display: inline-block;
                            // width: calc(5 * 1.6rem);
                            font-size: 1.6rem;
                            color: #fff;
                            line-height: 2.5;
                            text-align: justify;
                            margin-top: calc((1.6rem * 2.5) * (-1));

                            &::after {
                                display: inline-block;
                                width: 100%;
                                content: "";
                                line-height: 0;
                            }
                        }
                    }
                }
            }
        }

        .bottom {
            padding-top: 5.2%;
            padding-left: 13%;
            display: flex;
            padding-bottom: 11%;

            .friend {
                flex: 1;

                .btn {
                    * {
                        color: inherit;
                        position: relative;
                        color: #fff;
                        z-index: 99;
                    }
                }
            }

            .title {
                width: calc(2.4rem * 7);
                font-size: 2.4rem;
                line-height: 1.5;
                color: #fff;
            }

            .desc {
                width: 90%;
                margin-top: 1.5rem;
                // font-size: 1.4rem;
                line-height: 2rem;
                color: #fff;
                height: calc(1.4rem * 2 * 2);
            }

            .btn {
                font-size: 1.8rem;
                color: #fff;
                padding: 11px 36px;
                border: 1px #fff solid;
                display: inline-block;
                margin-top: 7px;
                border-radius: 30px;
                margin-left: 2px;
                cursor: pointer;
                background-image: linear-gradient(to left, transparent, #b9985e);
                background-size: 400px;
                background-repeat: no-repeat;
                background-position: -400px;
                transition: .7s;

                &:hover {
                    background-position: 0px;
                }

                // display: ;
            }
        }
    }

    &>.right {
        border-left: #407b6b 1px solid;
        flex: 1;
        box-sizing: border-box;
        padding-top: 3.6%;
        padding-left: 7.5%;

        .name-cn {
            font-size: 2.4rem;
            font-weight: 400;
            color: #fff;
        }

        .name-en {
            color: #fff;
            font-weight: 400;
            font-size: 1.6rem;
            margin-bottom: 5.3rem;
        }

        .adds {
            margin-bottom: 50px;

            .title {
                padding-left: 3px;
                position: relative;
                padding-left: 35px;

                span {
                    font-size: 1.8rem;
                    color: #fff;
                }

                .icon {
                    position: absolute;
                    left: 2px;
                    width: 20px;
                    height: 24px;
                    // background: rgba(255, 255, 255, 0.6);
                    display: inline-block;
                    // margin-top: 5px;
                    margin-right: 10px;
                    background-image: url(../../assets/icons_01.png);
                    background-position: -66px -32px;
                    background-size: 130px;
                    transform: scale(0.9);


                    img {
                        display: none;
                    }
                }
            }

            ul {
                padding-left: 35px;
                margin-top: 5px;

                li {
                    font-size: 1.4rem;
                    color: #fff;
                    list-style: none;
                    line-height: 1.8;
                }
            }

            .cont {
                padding-left: 35px;
                margin-top: 5px;
                font-size: 1.8rem;
                color: #fff;
                list-style: none;
                line-height: 1.8;
            }
        }

        .tel {
            float: left;

            .title {
                .icon {
                    width: 20px;
                    height: 24px;
                    top: -1px;
                    background-position: -21px -32px;
                    background-size: 130px;
                    transform: scale(0.9);
                }
            }

            .cont {
                line-height: 1;
            }
        }

        .fax {
            float: left;
            margin-left: 38px;

            .title {
                .icon {
                    width: 21px;
                    height: 22px;
                    top: -5px;
                    left: 0;
                    background-position: 43px 2px;
                    background-size: 130px;
                }
            }

            .cont {
                line-height: 1;
            }
        }

        .qrs {
            width: 50%;
            display: flex;
            box-sizing: border-box;
            padding-left: 38px;
            margin-top: 22px;
            justify-content: space-between;

            .pic {
                width: 115px;
                height: 115px;
            }

            p {
                color: #fff;
                font-size: 1.4rem;
                text-align: center;
                margin-top: 24px;
            }
        }
    }

    .beian {
        width: 100%;
        height: 182px;
        box-sizing: border-box;
        padding-top: 54px;
        padding-left: 8%;
        display: flex;
        justify-content: space-between;
        padding-right: 19.45%;

        * {
            color: #fff;
        }

        p {
            color: #fff;
            font-size: 1.4rem;
            white-space: pre;
        }

        a {
            color: #fff;
        }

        .zhichi {
            font-size: 1.4rem;
            line-height: 2.5;
            display: inline;
        }

        .links {
            display: inline;
            margin-left: 40px;

            span {
                font-size: 1.4rem;
            }

            a {
                font-size: 1.4rem;
                padding: 0 12px;
                position: relative;

                &::after {
                    content: "";
                    display: block;
                    height: 1.4rem;
                    width: 1px;
                    background: #fff;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translate(50%, -50%);

                }

            }

            a:last-child {
                &::after {
                    display: none;
                }
            }
        }

        .right {
            width: 14.7%;

            .menu {
                width: 100%;
            }

            ul {
                display: flex;
                justify-content: space-between;
                width: 100%;

                li {
                    width: 20px;
                    height: 20px;
                    // background: #fff;
                    list-style: none;
                    margin-top: 15.4px;

                    // opacity: .5;
                    .pic {
                        width: 100%;
                        height: 100%;
                        background-image: url(../../assets/icons_01.png);
                        filter: brightness(10);
                        transition: .3s;
                        cursor: pointer;

                        &:hover {
                            filter: brightness(1);
                        }

                        img {
                            display: none;
                        }
                    }

                    .icon1 {
                        background-size: 136px;
                        background-position: -93px -141px;
                    }

                    .icon2 {
                        width: 18px;
                        background-image: url(../../assets/index/facebook.png);
                        background-repeat: no-repeat;
                        background-size: contain;
                        // background-position: 0px 0px;
                    }

                    .icon3 {
                        background-size: 136px;
                        background-position: -17px 1px;
                    }

                    .icon4 {
                        background-size: 136px;
                        background-position: -43px 3px;
                    }

                    .icon5 {
                        background-size: 136px;
                        background-position: -66px 3px;
                    }
                }
            }
        }
    }
}

@media all and (max-width:1100px) {
    .footer {
        padding-top: 67px;

        &>.left,
        &>.right {
            padding-left: 30px;
            width: 50%;
        }

        &>.left {

            .top {
                padding-left: 0px;

                .links {

                    .title {}

                    .link {

                        li {

                            a {

                                &::after {}
                            }
                        }
                    }
                }
            }

            .bottom {
                padding-left: 0px;

                .friend {}

                .title {}

                .desc {
                    height: auto;
                }

                .btn {}
            }
        }

        &>.right {
            padding-right: 30px;
            padding-left: 2.5%;

            .name-cn {}

            .name-en {}

            .adds {
                margin-bottom: 25px;

                .title {

                    span {}

                    .icon {
                        img {}
                    }
                }

                ul {

                    li {}
                }

                .cont {}
            }

            .tel {
                margin-bottom: 25px;

                .title {
                    .icon {}
                }

                .cont {}
            }

            .fax {
                margin-bottom: 25px;

                .title {
                    .icon {}
                }

                .cont {}
            }

            .qrs {
                padding-left: 0;

                .gzh {
                    margin-right: 30px;
                }

                .pic {}

                p {}
            }
        }

        .beian {
            padding-left: 30px;
            padding-right: 30px;

            * {}

            p {}

            a {}

            .zhichi {}

            .links {

                span {}

                a {

                    &::after {}

                }

                a:last-child {
                    &::after {}
                }
            }

            .right {
                flex: 1;
                margin-left: 60px;

                .menu {}

                ul {
                    li {
                        margin-top: 0;

                        .pic {
                            img {}
                        }

                        .icon1 {}

                        .icon2 {}

                        .icon3 {}

                        .icon4 {}

                        .icon5 {}
                    }
                }
            }
        }
    }
}

@media all and (max-width:769px) {
    .footer {
        height: auto;

        padding-top: 30px;

        &>.left,
        &>.right {
            width: 100%;
            margin: 0 0 0 0;
            padding: 0 0 0 0;
        }

        &>.left {

            .top {
                width: 100%;
                padding: 30px 15px 0 15px;
                box-sizing: border-box;

                .links {

                    .title {
                        font-size: 2.1rem;
                    }

                    .link {

                        li {

                            a {

                                &::after {}
                            }
                        }
                    }
                }
            }

            .bottom {
                padding: 30px 15px 0 15px;
                flex-wrap: wrap;

                .friend {
                    width: 100%;
                    margin-bottom: 30px;
                    flex: auto;
                }

                .title {
                    width: 100%;
                    margin: 0 0 0 0;
                }

                .desc {}

                .btn {}
            }
        }

        &>.right {
            display: none;
        }

        &>.beian {
            padding: 30px 15px 0 15px;
            width: 100%;
            flex-wrap: wrap;
            height: auto;
            padding-bottom: 30px;

            p {
                line-height: 2.5;
            }

            &>.left,
            &>.right {
                width: 100%;
            }

            &>.left {
                order: 2;
                width: 100%;

                .beian-cont {
                    width: 87%;
                    white-space: normal;

                }

                .zhichi {
                    display: block;
                }

                .links {
                    display: block;
                    margin-left: 0px;
                }
            }

            &>.right {
                order: 1;
                margin-left: 0px;
                margin-bottom: 15px;

                .menu {
                    width: 60%;
                }
            }
        }

    }
}

@media all and (min-width:1921px) {
    .footer {
        padding-top: 67px;

        &>.left,
        &>.right {}

        &>.left {

            .top {
                padding-top: 250px;

                .links {

                    .title {}

                    .link {

                        li {

                            a {

                                &::after {}
                            }
                        }
                    }
                }
            }

            .bottom {

                .friend {}

                .title {}

                .desc {}

                .btn {}
            }
        }

        &>.right {
            padding-top: 250px;

            .name-cn {}

            .name-en {}

            .adds {

                .title {

                    span {}

                    .icon {
                        img {}
                    }
                }

                ul {

                    li {}
                }

                .cont {}
            }

            .tel {
                .title {
                    .icon {}
                }

                .cont {}
            }

            .fax {

                .title {
                    .icon {}
                }

                .cont {}
            }

            .qrs {
                .pic {}

                p {}
            }
        }

        .beian {

            * {}

            p {}

            a {}

            .zhichi {}

            .links {

                span {}

                a {

                    &::after {}

                }

                a:last-child {
                    &::after {}
                }
            }

            .right {
                .menu {}

                ul {
                    li {
                        .pic {
                            img {}
                        }

                        .icon1 {}

                        .icon2 {}

                        .icon3 {}

                        .icon4 {}

                        .icon5 {}
                    }
                }
            }
        }
    }
}

@media all and (max-width:1px) {
    .footer {

        &>.left,
        &>.right {}

        &>.left {
            padding-top: 200px;

            .top {
                .links {

                    .title {}

                    .link {

                        li {

                            a {

                                &::after {}
                            }
                        }
                    }
                }
            }

            .bottom {

                .friend {}

                .title {}

                .desc {}

                .btn {}
            }
        }

        &>.right {

            .name-cn {}

            .name-en {}

            .adds {

                .title {

                    span {}

                    .icon {
                        img {}
                    }
                }

                ul {

                    li {}
                }

                .cont {}
            }

            .tel {
                .title {
                    .icon {}
                }

                .cont {}
            }

            .fax {

                .title {
                    .icon {}
                }

                .cont {}
            }

            .qrs {
                .pic {}

                p {}
            }
        }

        .beian {

            * {}

            p {}

            a {}

            .zhichi {}

            .links {

                span {}

                a {

                    &::after {}

                }

                a:last-child {
                    &::after {}
                }
            }

            .right {
                .menu {}

                ul {
                    li {
                        .pic {
                            img {}
                        }

                        .icon1 {}

                        .icon2 {}

                        .icon3 {}

                        .icon4 {}

                        .icon5 {}
                    }
                }
            }
        }
    }
}

@media (any-hover:hover) {
    a:hover {
        color: #b9985e !important;
    }

    .friend {
        .btn {
            a {
                color: #fff !important;
            }
        }
    }
}
</style>